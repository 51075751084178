import styled from "styled-components";

const CustomButton = styled.button`
  font-family: "Merriweather", serif !important;
  font-weight: 900;
  color: ${(props) => (props.color ? props.color : "#ffffff")} !important;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : "1.06rem"} !important;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#416165"}!important;
  border-radius: 9px !important;
  cursor: pointer;
  border-bottom-left-radius: 0px !important;
  padding: ${(props) => (props.padding ? props.padding : "0.75rem 1rem")};
  border-color: ${(props) =>
    props.borderColor
      ? props.borderColor
      : props.backgroundColor
      ? props.backgroundColor
      : "#416165"}!important;
  border: ${(props) => (props.borderWidth ? props.borderWidth : "")}!important;
  text-align: center;
  text-transform: none;
  line-height: 1;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "0")}px !important;
  min-height: ${(props) =>
    props.minHeight ? props.minHeight : "0"}px !important;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "5px 5px 0 rgba(10,56,63,.08)"};
  margin-right: ${(props) =>
    props.marginRight ? props.marginRight : "0"}px !important;
  margin-left: ${(props) =>
    props.marginLeft ? props.marginLeft : "0"}px !important;
  margin-top: ${(props) =>
    props.marginTop ? props.marginTop : "0"}px !important;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "0"}px !important;
  &:hover {
    background-color: #f3e8ee !important;
    color: #643368 !important;
  }
`;

export { CustomButton };
