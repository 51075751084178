import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { onValue, ref, set, getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyA332MFh41Rs4KdB_IRswCLJS_vl3SS5xE",
  authDomain: "annemin-dunyasi-anne-bebek.firebaseapp.com",
  databaseURL: "https://annemin-dunyasi-anne-bebek.firebaseio.com",
  projectId: "annemin-dunyasi-anne-bebek",
  storageBucket: "annemin-dunyasi-anne-bebek.appspot.com",
  messagingSenderId: "635283240268",
  appId: "1:635283240268:web:482a21ea4185523cedcbe9",
  measurementId: "G-HM4TV66EK7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const database = getDatabase(app);
const dbRef = ref(getDatabase());

const sendLogEvent = (event) => {
  logEvent(analytics, event);
};
export { analytics, auth, database, dbRef, ref, set, onValue, sendLogEvent };
