import "./App.css";
import "./css/Footer.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import Footer from "./component/Footer";
import PublicHeader from "./component/PublicHeader";
import ScaleLoader from "react-spinners/ScaleLoader";
import AppStore from "./route/AppStore";
import HomePage from "./route/HomePage";
import { sendLogEvent } from "./fConfiguration";

const renderLoader = () => (
  <div
    style={{
      display: "flex",
      flexGrow: 1,
      height: "50vmax",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <h1
      className="font-merriweather"
      style={{ color: "#ffffff", fontSize: 18 }}
    >
      Hamilelik Belirtileri ve Gebelik Takibi, Hafta Hafta Bebek Gelişimi
      Uygulaması ile öğrenin, birlikte büyüyün.
    </h1>
    <h2
      className="font-merriweather"
      style={{ color: "#ffffff", fontSize: 18 }}
    >
      Haftalık hamilelik takvimini önümüzdeki dokuz ay için nihai rehberiniz
      olarak kabul edin.
    </h2>
    <h3
      className="font-merriweather"
      style={{ color: "#ffffff", fontSize: 18 }}
    >
      Momercy: Annelerin ve Bebeklerin Güvenilir Rehberi.
    </h3>
    <ScaleLoader
      color={"#a4a4ff"}
      width={20}
      height={50}
      radius={30}
      margin={4}
    />
  </div>
);

const SeoArticles = lazy(() => import("./route/SeoArticles"));
const FaqArticles = lazy(() => import("./route/FaqArticles"));
const BoyNamesPage = lazy(() => import("./route/BoyNamesPage"));
const FaqArticlesPregnancy = lazy(() => import("./route/FaqArticlesPregnancy"));
const PregnancyBabyTools = lazy(() => import("./route/PregnancyBabyTools"));
const ArticleSubjectsPage = lazy(() => import("./route/ArticleSubjectsPage"));
//const Forum = lazy(() => import("./route/Forum"));
const ArticlesPage = lazy(() => import("./route/ArticlesPage"));
const ArticlePage = lazy(() => import("./route/ArticlePage"));
const OnlyArticlePage = lazy(() => import("./route/OnlyArticlePage"));
const BudgetCalculatePage = lazy(() => import("./route/BudgetCalculatePage"));
const PersentileCalculatePage = lazy(() =>
  import("./route/PersentileCalculatePage")
);
const VaccineDatePage = lazy(() => import("./route/VaccineDatePage"));
const PregnancyTestPage = lazy(() => import("./route/PregnancyTestPage"));
const PregnancyMounthCalculatePage = lazy(() =>
  import("./route/PregnancyMounthCalculatePage")
);
const OvulationCalculator = lazy(() => import("./route/OvulationCalculator"));
const ChineseGender = lazy(() => import("./route/ChineseGender"));
const BabyNamesPage = lazy(() => import("./route/BabyNamesPage"));
const DueDateCalculator = lazy(() => import("./route/DueDateCalculator"));
const ZodiacCalculator = lazy(() => import("./route/ZodiacCalculator"));
const TupPregnancyMounthCalculatePage = lazy(() =>
  import("./route/TupPregnancyMounthCalculatePage")
);
const WeeklyTrackerPage = lazy(() => import("./route/WeeklyTrackerPage"));
const AppLinkAreaTop = lazy(() => import("./component/AppLinkAreaTop"));
const ShopLinkAreaTop = lazy(() => import("./component/ShopLinkAreaTop"));

function App() {
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;
  let isNormalScreen = width <= 1440 && width >= 800;
  let isHighScreen = width <= 1740 && width >= 1440;

  const reload = () => window.location.reload();

  const openInNewTab = () => {
    closePopupMobile();
    sendLogEvent("click_mobile_market_link_top");
    const val = getMobileOperatingSystem();
    if (val === 1) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.annebebek.annemindunyasi",
        "_blank",
        "noopener,noreferrer"
      );
    } else if (val === 2) {
      window.open(
        "https://apps.apple.com/us/app/mom-talks-bebek-geli%C5%9Fimi/id1447682983",
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 0;
    }

    if (/android/i.test(userAgent)) {
      return 1;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 2;
    }

    return 0;
  }

  window.onload = function () {
    setTimeout(function () {
      /*if (isMobile) {
        document
          .getElementById("momercyshop-popup-mobile")
          .classList.add("show-popup");
        setTimeout(function () {
          document
            .getElementById("momercyshop-popup")
            .classList.add("show-popup");
        }, 3000);
      } else {*/
      document.getElementById("momercyshop-popup").classList.add("show-popup");
      //}
    }, 4000); // 3 saniye sonra popup gösterilecek
  };

  function closePopupMobile() {
    document
      .getElementById("momercyshop-popup-mobile")
      .classList.remove("show-popup");
  }

  function closePopup() {
    document.getElementById("momercyshop-popup").classList.remove("show-popup");
  }

  return (
    <BrowserRouter>
      <div style={{ width: "100%" }}>
        <PublicHeader isMobile={isMobile} />
        {isMobile && <AppLinkAreaTop />}
        {isMobile && <ShopLinkAreaTop />}
        <div id="momercyshop-popup" class="popup-overlay">
          <div class="popup-content">
            <img
              src="/images/momercyshop.webp"
              alt="MomercyShop Organik Ürünler"
              class="popup-image"
            />
            <span class="close-btn" onClick={closePopup}>
              &times;
            </span>

            <h2>Organik ve Güvenilir Ürünlerle Tanışın</h2>
            <p style={{ paddingLeft: 20, paddingRight: 20 }}>
              Bebeğiniz ve sizin için özenle seçilmiş organik ve yüksek kaliteli
              ürünler{" "}
              <span style={{ color: "#f15a24", fontWeight: "800" }}>
                MomercyShop
              </span>
              ' ta. Doğanın saflığını evinize taşıyın.
            </p>
            <a
              href="https://momercyshop.com"
              class="shop-now-btn"
              target="_blank"
              rel="noreferrer"
            >
              Şimdi Keşfedin
            </a>
          </div>
        </div>

        <div id="momercyshop-popup-mobile" class="popup-overlay">
          <div class="popup-content">
            <img
              alt="Hafta Hafta Hamilelik ve Bebek Gelişimi"
              src="https://amplify-amplify5fdda0668dad4-staging-120746-deployment.s3.eu-central-1.amazonaws.com/momercy/images/hamilelik.jpeg"
              className="lazyload"
              class="popup-image2"
            />
            <span class="close-btn" onClick={closePopupMobile}>
              &times;
            </span>

            <h2>Hamilelik Takibi ve Bebek Gelişimi</h2>

            <p
              className="general-p2"
              style={{
                textAlign: "center",
                padding: 0,
                margin: 10,
                fontSize: 16,
              }}
            >
              #1 numaralı hamilelik takibi ve bebek gelişimi uygulamamızı hemen
              indirin!
            </p>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                marginTop: 10,
              }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.annebebek.annemindunyasi&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                onClick={() =>
                  openInNewTab(
                    "https://play.google.com/store/apps/details?id=com.annebebek.annemindunyasi&hl"
                  )
                }
                style={{
                  textDecoration: "none",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  variant="contained"
                  size="medium"
                  style={{
                    backgroundColor: "#442b48",
                    fontSize: 16,
                    color: "#fff",
                    fontWeight: 800,
                    textAlign: "center",
                    borderRadius: 20,
                    padding: 5,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  Ücretsiz İndir
                </p>
              </a>
            </div>
          </div>
        </div>

        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={renderLoader()}>
                <HomePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en"
            element={
              <Suspense fallback={renderLoader()}>
                <HomePage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/makaleler/:mom/:category/:article"
            element={
              <Suspense fallback={renderLoader()}>
                <OnlyArticlePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/articles/:mom/:category/:article"
            element={
              <Suspense fallback={renderLoader()}>
                <OnlyArticlePage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/makaleler/:mom/:category/:article/:article2"
            element={
              <Suspense fallback={renderLoader()}>
                <OnlyArticlePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/articles/:mom/:category/:article/:article2"
            element={
              <Suspense fallback={renderLoader()}>
                <OnlyArticlePage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/anne-cocuk-bebek-urun-onerileri/:article"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlesPage />
              </Suspense>
            }
          />
          <Route
            path="/en/mother-child-baby-product-recommendations/:article"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlesPage loc="en" />
              </Suspense>
            }
          />
          <Route
            path="/hafta-hamile-gebelik-takibi/:article"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlesPage />
              </Suspense>
            }
          />
          <Route
            path="/en/week-pregnant-tracking/:article"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlesPage loc="en" />
              </Suspense>
            }
          />
          <Route
            path="/hafta-hafta-hamilelik-gebelik-takibi-gelisimi/:hafta"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/week-week-weekly-pregnancy-follow-up-development/:hafta"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hafta-hafta-hamilelik-kontrol-listesi-takibi/:hafta"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/week-week-pregnancy-checklist-tracking/:hafta"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/ay-ay-hamilelikte-gebelikte-beslenme-saglik/:ay"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/month-pregnancy-nutrition-health/:ay"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelik-gebelik-belirtileri-semptomlar/:id"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-signs-symptoms/:id"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hafta-hafta-bebek-gelisimi/:id"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/week-week-baby-development-tracking/:id"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/ay-ay-bebek-beslenme/:id"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/month-month-baby-nutrition/:id"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/ay-ay-bebek-gelisimi-takibi-kontrol-listesi/:id"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hafta-hafta-bebek-gelisimi-aktiviteleri/:id"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelik-planlama-ovulasyon/:article"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlesPage />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-planning-ovulation/:article"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlesPage loc="en" />
              </Suspense>
            }
          />
          <Route
            path="/dogum-bebek-gelisimi-takibi/:article"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlesPage />
              </Suspense>
            }
          />
          <Route
            path="/en/birth-baby-development-tracking/:article"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlesPage loc="en" />
              </Suspense>
            }
          />
          <Route
            path="/anne-cocuk-gelisimi-takibi/:article"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlesPage />
              </Suspense>
            }
          />
          <Route
            path="/en/mother-child-development-follow-up/:article"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticlesPage loc="en" />
              </Suspense>
            }
          />
          <Route
            path="/asi-takvimi-hesaplama"
            element={
              <Suspense fallback={renderLoader()}>
                <VaccineDatePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/rebel-calendar-calculator"
            element={
              <Suspense fallback={renderLoader()}>
                <VaccineDatePage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamile-miyim-test-yapmak"
            element={
              <Suspense fallback={renderLoader()}>
                <PregnancyTestPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/am-pregnant-test-do"
            element={
              <Suspense fallback={renderLoader()}>
                <PregnancyTestPage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/bebek-hamile-butce-gider-hesaplama"
            element={
              <Suspense fallback={renderLoader()}>
                <BudgetCalculatePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/baby-pregnant-budget-expenses-calculation"
            element={
              <Suspense fallback={renderLoader()}>
                <BudgetCalculatePage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/kac-aylik-hamileyim-hesaplama"
            element={
              <Suspense fallback={renderLoader()}>
                <PregnancyMounthCalculatePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/how-many-months-am-pregnant"
            element={
              <Suspense fallback={renderLoader()}>
                <PregnancyMounthCalculatePage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/kilo-boy-bas-cevresi-persintile-hesaplama"
            element={
              <Suspense fallback={renderLoader()}>
                <PersentileCalculatePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/weight-height-bass-percentile-calculation"
            element={
              <Suspense fallback={renderLoader()}>
                <PersentileCalculatePage
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/weight-height-head-perimeter-persintile-calculate"
            element={
              <Suspense fallback={renderLoader()}>
                <PersentileCalculatePage isMobile={isMobile} loc="en" />
              </Suspense>
            }
          />
          <Route
            path="/due-date-calculator-tahmini-dogum-tarihi-hesaplama"
            element={
              <Suspense fallback={renderLoader()}>
                <DueDateCalculator
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/anne-bebek-burc-uyumu-hesaplama"
            element={
              <Suspense fallback={renderLoader()}>
                <ZodiacCalculator
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/mother-baby-zodiac-compatibility-calculation"
            element={
              <Suspense fallback={renderLoader()}>
                <ZodiacCalculator
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/tup-bebek-gebelik-hesaplama"
            element={
              <Suspense fallback={renderLoader()}>
                <TupPregnancyMounthCalculatePage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/due-date-calculator"
            element={
              <Suspense fallback={renderLoader()}>
                <DueDateCalculator
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/kiz-erkek-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/kesfedilmemis-erkek-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage kesfedilmemis isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/unisex-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage unisex isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/kesfedilmemis-kiz-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage kesfedilmemiskiz isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/kuran-da-gecen-erkek-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage kuranerkek isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/kuran-da-gecen-kiz-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage kurankiz isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/en-cok-begenilen-erkek-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage enbegenilenerkek isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/en-cok-begenilen-kiz-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage enbegenilenkiz isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/modern-erkek-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage modernerkek isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/modern-kiz-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage modernkiz isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/nadir-erkek-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage nadirerkek isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/nadir-kiz-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage nadirkiz isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/populer-kiz-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage populerkiz isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/populer-erkek-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BabyNamesPage populererkek isMobile={isMobile} />
              </Suspense>
            }
          />
          <Route
            path="/fertile-ovulasyon-tarihleri-hesaplama"
            element={
              <Suspense fallback={renderLoader()}>
                <OvulationCalculator
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/fertile-ovulation-dates-calculation"
            element={
              <Suspense fallback={renderLoader()}>
                <OvulationCalculator
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/cin-cinsiyet-tahmini-hesaplama"
            element={
              <Suspense fallback={renderLoader()}>
                <ChineseGender
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/china-gender-estimate-calculation"
            element={
              <Suspense fallback={renderLoader()}>
                <ChineseGender
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/butun-tuhaf-hamilelik-belirtileri"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={30}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelik-takibi-gunlugu"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={29}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelik-belirtileri-nelerdir"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={2}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/yuzde-yuz-hamilelik-belirtileri"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={7}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={25}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/privacy-policy"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={26}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/iletisim"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={27}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/contact"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={28}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hakkimizda"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={23}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelik-kac-gunder-belli-olur"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={34}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-16-hafta-bebegin-gelisimi"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={35}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-ilk-uc-ay-belirtiler"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={36}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/dogum-cantasi-hazirligi"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={37}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-1.trimester:-bilmeniz-gereken-her-sey"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={38}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelik-ve-bebek-bakimi-momercy"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={39}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-saglikli-yasam-egzersiz"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={40}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelik-ve-gebelik-takibi-saglikli"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={41}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-gun-gun-bebek-gelisimi"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={42}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/about-us"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={24}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/yuzde-yuz-hamilelik-belirtileri-nelerdir"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={31}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/tuhaf-hamilelik-belirtileri"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={10}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamile-kalmak-icin"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={11}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/anne-yani-besik=modelleri"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={12}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/are-pregnancy-tests-accurate"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={13}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-discharge"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={14}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-dreams"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={15}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-due-date-calculator"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={16}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-early-symptoms"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={17}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/kullanim-kosullari"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  index={32}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/terms-conditions"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={33}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-insomnia"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={18}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-journal"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={19}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-massage"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={20}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-spotting"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={21}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-week-by-week"
            element={
              <Suspense fallback={renderLoader()}>
                <SeoArticles
                  loc="en"
                  index={22}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/bebek-sik-sorulan-sorular"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticles
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/bebeklerde-gaz-sancısına-neler-iyi-gelir"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticles
                  index={6}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/baby-frequently-asked-questions"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticles
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-sik-sorulan-sorular"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticlesPregnancy
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-mide-yanmasına-ne-iyi-gelir"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticlesPregnancy
                  index={17}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-kabızlıga-ne-iyi-gelir"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticlesPregnancy
                  index={18}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-mide-bulantisina-ne-iyi-gelir"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticlesPregnancy
                  index={23}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-kasik-agrisina-ne-iyi-gelir"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticlesPregnancy
                  index={25}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-bel-agrisina-ne-iyi-gelir"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticlesPregnancy
                  index={26}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/erkek-bebek-isimleri"
            element={
              <Suspense fallback={renderLoader()}>
                <BoyNamesPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-bas-agrisina-ne-iyi-gelir"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticlesPregnancy
                  index={27}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-gaz-sancisina-ne-iyi-gelir"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticlesPregnancy
                  index={28}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-idrar-yolu-enfeksiyonuna-ne-iyi-gelir"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticlesPregnancy
                  index={29}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-oksuruge-ne-iyi-gelir"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticlesPregnancy
                  index={42}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-gribe-ne-iyi-gelir"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticlesPregnancy
                  index={44}
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-frequently-asked-questions"
            element={
              <Suspense fallback={renderLoader()}>
                <FaqArticlesPregnancy
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/ay-ay-bebek-beslenme"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Hamilelik takibi, Hamilelikte ve gebelikte ay ay beslenme takvimi, önerileri ile hem sağlınızı hem de bebeğinizin gelisimini kontrol altında tutabilirsiniz."
                  link="https://www.momercy.com/ay-ay-bebek-beslenme"
                  header="Ay Ay Bebek Beslenmesi"
                  subHead="Emzirme, biberonla besleme ve ek gıda hakkında bilmeniz gereken her şey; ipuçlarından sorunları nasıl gidereceğinize, örnek beslenme planlarından bebek yemek tariflerine kadar..."
                  p1="Bebekler sofra adabı ve yeni yiyecekler denemeye istekli olmaları ile tanınmazlar, ancak bu ilk yıllar sağlıklı beslenme alışkanlıklarını tanıtmanın en önemli zamanıdır. Bu nedenle, tüm öğünlere sağlık katmak, ayına uygun sağlıklı besinleri öğrenmek ve daha fazlası hakkında bilgi edinmek için aylık ipuçları bulabileceğiniz bu kılavuzu kullanın."
                  type={5}
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/month-month-baby-nutrition"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Everything you need to know about breastfeeding, bottle feeding and complementary feeding; From tips to how to troubleshoot, from sample feeding plans to baby recipes.."
                  link="https://www.momercy.com/en/month-month-baby-nutrition"
                  header="Baby Feeding Month by Month"
                  subHead="Everything you need to know about breastfeeding, bottle feeding and complementary feeding; From tips to how to troubleshoot, from sample feeding plans to baby recipes.."
                  p1="Babies aren't known for their table manners and willingness to try new foods, but these early years are the most important time to introduce healthy eating habits. So use this monthly tips guide to add health to all meals, learn about healthy foods for the month, and more."
                  type={5}
                  loc="en"
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/hafta-hafta-bebek-gelisimi-takibi"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Hafta hafta bebek gelişimi, çoçuk gelişimi, büyümesi. Bebeğinizin ilk yılları ve tüm önemli kilometre taşları (ilk gülümseme, kıkırdama, adım, kelimeler...), zorluklar (kolik, diş çıkarma, pişik...) ve daha fazlası için rehberiniz. "
                  link="https://www.momercy.com/hafta-hafta-bebek-gelisimi-takibi"
                  header="Hafta Hafta Bebek Gelişimi"
                  subHead="Bebeğinizin ilk yıllardaki büyümesi ve gelişmesi hem çok keyifli hem de çok hızlı! İşte bebeğinizin ilk yılları ve tüm önemli kilometre taşları (ilk gülümseme, kıkırdama, adım, kelimeler...), zorluklar (kolik, diş çıkarma, pişik...) ve daha fazlası için rehberiniz."
                  p1="Küçük enerji ve neşe paketinizin gelişimini izlemek (ilk adımları, ilk cümle, ilk karalanmış resim) heyecan verici olduğu kadar, zorlu da bir süreç. Bebeğiniz yeni beceriler deniyor, farkındalık geliştiriyor ve bağımsızlık ve bağlılıkla mücadele ediyor. O tüm bunları yaparken görgü ve özdenetim öğretmeye çalışıyorsunuz. Bir yandan iyi uyusun, iyi beslensin diye emek verirken öte yandan duygusal ve bilişsel gelişimi için uğraşıyorsunuz. İşte, bebeğinizin gelişiminin içini ve dışını çekip çevirmenize yardımcı olacak haftalık ipuçlarından oluşan detaylı bir kılavuz."
                  type={4}
                  isNormalScreen={isNormalScreen}
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/week-week-baby-development-tracking"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Week by week baby development, child development, growth. Your guide to your baby's first years and all the important milestones (first smile, giggle, step, words...), challenges (colic, teething, nappy rash...) and more."
                  link="https://www.momercy.com/en/week-week-baby-development-tracking"
                  header="Week by Week Baby Development"
                  subHead="Your baby's growth and development in the first years is both very enjoyable and very fast! Here's your guide to your baby's first years and all the important milestones (first smile, giggle, step, words...), challenges (colic, teething, nappy rash...) and more."
                  p1="Watching your little bundle of energy and joy evolve (first steps, first sentence, first scribbled picture) is as exciting as it is challenging. Your baby is trying new skills, developing awareness, and struggling with independence and commitment. While he is doing all this, you are trying to teach manners and self-control. On the one hand, you work hard to sleep well and eat well, on the other hand, you strive for his emotional and cognitive development. Here's a detailed guide of weekly tips to help you navigate the ins and outs of your baby's development."
                  type={4}
                  loc="en"
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/bebek-ilk-yardim"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="İlk yardım tavsiyeleri, çocuklarınızın başına gelebilecek acil durumlarda sakin kalmanıza hatta belki de hayatını kurtarmanıza yardımcı olacaktır."
                  link="https://www.momercy.com/bebek-ilk-yardim"
                  header="Bebekler ve Küçük Çocuklar İçin İlk Yardım"
                  subHead="İlk yardımın ana amacı, mevcut durumun kökten tedavisi değildir. Öncelikli hedef; olay yerinde, sağlık görevlilerinin yardımı sağlanıncaya kadar, hayatın kurtarılması ya da durumun kötüye gitmesini önleyebilmektir. Aşağıdaki tavsiyeler, çocuklarınızın başına gelebilecek acil durumlarda sakin kalmanıza hatta belki de hayatını kurtarmanıza yardımcı olacaktır."
                  type={8}
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/baby-first-aid"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="First aid advice will help you stay calm in the event of an emergency, and perhaps even save your child's life."
                  link="https://www.momercy.com/en/baby-first-aid"
                  header="First Aid for Babies and Young Children"
                  subHead="The main purpose of first aid is not the radical treatment of the current situation. primary target; The aim is to save life or prevent the situation from getting worse until the help of paramedics is provided at the scene. The following advice will help you stay calm in the event of an emergency, and perhaps even save your child's life."
                  type={8}
                  loc="en"
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelik-gebelik-belirtileri-semptomlar"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Hamilelik belirtileri, hamilelikte ilk hafta belirtileri nelerdir, ne zaman başlar merak mı ediyorsunuz? Bu haftalık hamilelik takvimini önümüzdeki dokuz ay için nihai rehberiniz olarak kabul edin."
                  link="https://www.momercy.com/hamilelik-gebelik-belirtileri-semptomlar"
                  header="Hamilelik Gebelik Belirtileri - Semptomlar"
                  subHead="Hamilelik belirtileri, hamilelikte ilk hafta belirtileri nelerdir, ne zaman başlar merak mı ediyorsunuz? Bu haftalık hamilelik takvimini önümüzdeki dokuz ay için nihai rehberiniz olarak kabul edin."
                  type={3}
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-signs-symptoms"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="What are the signs of pregnancy, what are the symptoms of the first week of pregnancy, when does it start? Consider this weekly pregnancy calendar your ultimate guide to the next nine months."
                  link="https://www.momercy.com/en/pregnancy-signs-symptoms"
                  header="Pregnancy Symptoms"
                  loc="en"
                  subHead="Are you wondering what are the signs of pregnancy, what are the symptoms of the first week of pregnancy, when does it start? Consider this weekly pregnancy calendar your ultimate guide to the next nine months."
                  type={3}
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelikte-gebelikte-beslenme-saglik"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Hamilelik takibi, hamilelikte ve gebelikte ay ay beslenme takvimi, önerileri ile hem sağlınızı hem de bebeğinizin gelişimini kontrol altında tutabilirsiniz."
                  link="https://www.momercy.com/hamilelikte-gebelikte-beslenme-saglik"
                  header="Hamilelikte Beslenme ve Sağlık - Ay Ay Takip"
                  subHead="Bebeğinizi beklerken doğru beslenmek, sağlıklı bir hamilelik ve bebek sahibi olmanızı sağlamanın en iyi yollarından biridir. İşte iki kişilik yemek yerken ihtiyacınız olan ve olmayan tüm şeyler için bir rehber."
                  p1="Muhtemelen doğum öncesi bir vitamin alıyorsunuz ve hamilelik sırasında sağlıklı, dengeli bir beslenme programı sürdürmek için elinizden gelenin en iyisini yapıyorsunuz (o korkunç sabah bulantısı ile uğraşmanıza rağmen). Eğer öyleyse, harika bir başlangıç ​​yaptınız!"
                  p2="Her gün almanız gereken tüm vitamin ve besin öğelerini beslenme proramınıza dahil etmek, büyüyen bebeğinizi desteklemek, anemi, gestasyonel diyabet ve preeklampsi gibi gebelik komplikasyonları riskinizi azaltmak, doğum sonrası hızlı iyileşmek ve bebeğinizi bol bol emzirmek için yapabileceğiniz en iyi şeylerden biridir."
                  p3="Hamilelikte beslenme gereksinimlerinin çoğu, birkaç küçük ince ayar dışında, hamilelik öncesi ile aynıdır. İşte hamilelik sırasında ve sonrasında ihtiyaç duyacağınız tüm temel besinler ve tabağınıza koyabileceğiniz en iyi yiyecekler için rehberiniz."
                  type={2}
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-nutrition-health"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="You can keep both your health and the development of your baby under control with pregnancy follow-up, month-by-month nutrition calendar during pregnancy and pregnancy, and recommendations.."
                  link="https://www.momercy.com/en/pregnancy-nutrition-health"
                  header="Nutrition and Health in Pregnancy Month by Month"
                  subHead="Eating right while you're expecting your baby is one of the best ways to ensure you have a healthy pregnancy and baby. Here's a guide to all the things you need and don't need when dining for two."
                  p1="You're probably taking a prenatal vitamin and doing your best to maintain a healthy, balanced diet during pregnancy (despite dealing with that awful morning sickness). If so, you've had a great start!"
                  p2="Incorporating all the vitamins and nutrients you need every day into your diet is one of the best things you can do to support your growing baby, reduce your risk of pregnancy complications such as anemia, gestational diabetes and preeclampsia, recover quickly after delivery, and breastfeed your baby."
                  p3="Most of the nutritional requirements during pregnancy are the same as before pregnancy, except for a few minor tweaks. Here's your guide to all the essential nutrients you'll need during and after pregnancy, and the best foods to put on your plate."
                  type={2}
                  loc="en"
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/hafta-hafta-hamilelik-kontrol-listesi-takibi"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Bebeğinizin gebelik süresini hesaplamak için ihtiyacınız olacağından adetinizin ilk gününü kaydedin ve kontrol listesine dikkat edin."
                  link="https://www.momercy.com/hafta-hafta-hamilelik-kontrol-listesi-takibi"
                  header="Hafta Hafta Hamilelik Kontrol Listesi Takibi"
                  subHead="Bebeğinizin gebelik süresini hesaplamak için ihtiyacınız olacağından adetinizin ilk gününü kaydedin ve kontrol listesine dikkat edin."
                  p1="Hamileyken bebeğiniz her gün değişiyor ve vücudunuz buna ayak uyduruyor. Bu hamilelik takibi, hamileliğinizin haftalarını, aylarını ve üç aylık dönemlerini daha iyi anlamanıza yardımcı olacaktır; her biri siz ve müstakbel bebeğiniz için yeni gelişmeler ve kilometre taşları getiriyor."
                  p2="Bebeğinizin her hafta ne kadar büyük olduğu, yaşayabileceğiniz yaygın gebelik belirtileri, son üç aylık döneme girdikten sonra hazırlanmanın yolları ve doğumun yakın olduğuna dair işaretler dahil olmak üzere hamilelik sırasında her hafta neler olmasını bekleyebileceğiniz hakkında daha fazla bilgi edinmek için okumaya devam edin. Bu kılavuzla içeride ve dışarıda neler olduğunu ve neden olduğunu daha iyi anlayacaksınız."
                  type={9}
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/week-week-pregnancy-checklist-tracking"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Record the first day of your period and pay attention to the checklist, as you will need it to calculate the gestational age of your baby."
                  link="https://www.momercy.com/en/week-week-pregnancy-checklist-tracking"
                  header="Week by Week Pregnancy Checklist Tracking"
                  subHead="Record the first day of your period and pay attention to the checklist, as you will need it to calculate the gestational age of your baby."
                  p1="While you're pregnant, your baby changes every day and your body keeps up with it. This pregnancy tracker will help you better understand the weeks, months and trimesters of your pregnancy; each brings new developments and milestones for you and your baby-to-be."
                  p2="Read on to learn more about what you can expect to happen each week during pregnancy, including how big your baby is each week, common pregnancy symptoms you can experience, ways to prepare once you hit your final trimester, and signs that delivery is imminent. With this guide you will gain a better understanding of what is happening inside and out and why."
                  type={9}
                  loc="en"
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/hafta-hafta-haftalik-hamilelik-gebelik-takibi-gelisimi"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Hafta hafta hamilelik ve gebelik takibi, beslenme, sağlık, aktivite, egzersiz ve spor üzerine rehber kabul edebileceğiniz anne kılavuzu."
                  link="https://www.momercy.com/hafta-hafta-haftalik-hamilelik-gebelik-takibi-gelisimi"
                  header="Hafta Hafta Hamilelik Gebelik Takibi ve Gelişimi"
                  subHead="Bebeğinizin ilk tekmesini ne zaman hissedeceğinizi veya ilk hamilelik ultrasonunuzu ne zaman yapacağınızı mı merak ediyorsunuz? Bu haftalık hamilelik takvimini önümüzdeki dokuz ay için nihai rehberiniz olarak kabul edin."
                  p1="Hamileyken bebeğiniz her gün değişiyor ve vücudunuz buna ayak uyduruyor. Bu hamilelik takibi, hamileliğinizin haftalarını, aylarını ve üç aylık dönemlerini daha iyi anlamanıza yardımcı olacaktır; her biri siz ve müstakbel bebeğiniz için yeni gelişmeler ve kilometre taşları getiriyor."
                  p2="Bebeğinizin her hafta ne kadar büyük olduğu, yaşayabileceğiniz yaygın gebelik belirtileri, son üç aylık döneme girdikten sonra hazırlanmanın yolları ve doğumun yakın olduğuna dair işaretler dahil olmak üzere hamilelik sırasında her hafta neler olmasını bekleyebileceğiniz hakkında daha fazla bilgi edinmek için okumaya devam edin. Bu kılavuzla içeride ve dışarıda neler olduğunu ve neden olduğunu daha iyi anlayacaksınız."
                  type={1}
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/week-week-weekly-pregnancy-follow-up-development"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Week by week pregnancy and pregnancy tracking, baby development, your baby's height and weight, nutrition during pregnancy, health, activity and sports."
                  link="https://www.momercy.com/en/week-week-weekly-pregnancy-follow-up-development"
                  header="Week by Week Pregnancy Follow-up and Development of Pregnancy"
                  subHead="Wondering when you'll feel your baby's first kick or when you'll have your first pregnancy ultrasound? Consider this weekly pregnancy calendar your ultimate guide to the next nine months."
                  p1="While you're pregnant, your baby changes every day and your body keeps up with it. This pregnancy tracker will help you better understand the weeks, months and trimesters of your pregnancy; each brings new developments and milestones for you and your baby-to-be."
                  p2="Read on to learn more about what you can expect to happen each week during pregnancy, including how big your baby is each week, common pregnancy symptoms you can experience, ways to prepare once you hit your final trimester, and signs that delivery is imminent. With this guide you will gain a better understanding of what is happening inside and out and why."
                  type={1}
                  loc="en"
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/ay-ay-bebek-gelisimi-takibi-kontrol-listesi"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Hafta hafta hamilelik ve gebelik takibi, bebek gelişimi, bebeğinizin boyu ve kilosu, hamilelikte beslenme, sağlık, aktivite ve spor."
                  link="https://www.momercy.com/ay-ay-bebek-gelisimi-takibi-kontrol-listesi"
                  header="Ay Ay Bebek Kontrol Listesi Takibi ve Gelişimi"
                  subHead="Bebeğiniz için dört gözle beklemeniz gereken her şey, kilometre taşlarından bebeğin büyümesine, beslenmesinden uykusuna, kontrol listesi ve hayatı kolaylaştıracak ipuçlarına kadar herşey bu kılavuzda."
                  type={6}
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/month-month-baby-development-tracking-checklist"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Week by week, everything you need to look forward to for your baby, from milestones to baby's growth, feeding and sleep, checklist and tips to make life easier, is in this guide."
                  link="https://www.momercy.com/en/month-month-baby-development-tracking-checklist"
                  header="Month by Month Baby Checklist Follow-up and Development"
                  subHead="Everything you need to look forward to for your baby, from milestones to baby's growth, feeding and sleep, checklist and tips to make life easier, is in this guide."
                  type={6}
                  loc="en"
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/hafta-hafta-bebek-gelisimi-aktiviteleri"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Hafta hafta hamilelik ve gebelik takibi, bebek gelişimi, bebek aktiviteleri, bebek oyunları, hamilelikte beslenme, sağlık, aktivite ve spor."
                  link="https://www.momercy.com/hafta-hafta-bebek-gelisimi-aktiviteleri"
                  header="Hafta Hafta Bebek Gelişimi Aktiviteleri"
                  subHead="Bebeğinizin gelişiminde ve büyümesinde çok önem arz eden aktiviteler için dünyadaki birçok uzman tarafından ve tecrübelerden edinilen bilgiler. "
                  type={7}
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/week-week-baby-development-activities"
            element={
              <Suspense fallback={renderLoader()}>
                <WeeklyTrackerPage
                  description="Month by month, knowledge gained from many experts and experiences around the world for activities that are crucial to your baby's development and growth."
                  link="https://www.momercy.com/en/week-week-baby-development-activities"
                  header="Week by Week Baby Development Activities"
                  subHead="Knowledge gained from many experts and experiences around the world for activities that are crucial to your baby's development and growth."
                  type={7}
                  loc="en"
                  isMobile={isMobile}
                />
              </Suspense>
            }
          />
          <Route
            path="/hamilelik-planlama-ovulasyon"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="Yumurtlama veya ovülasyon takvimi takibi, hamile kalma yöntemleri, regl süresi ve anneliğe hazırlık için gerekli bilgileri bulabilirsiniz."
                  link="https://www.momercy.com/hamilelik-planlama-ovulasyon"
                  header="Hamilelik Planlama - Ovülasyon ve Yumurtlama Takvimi"
                  subHead="Hamilelik planlamada mümkün olan en sağlıklı hamileliğe hazırlanmak için gebe kalmaya çalışırken bilmeniz gerekenler."
                  type={1}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-planning-ovulation"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="You can find the necessary information for ovulation and ovulation calendar tracking, methods of getting pregnant, menstrual period and preparation for motherhood."
                  link="https://www.momercy.com/en/pregnancy-planning-ovulation"
                  header="Pregnancy Planning - Ovulation and Ovulation Calendar"
                  subHead="Here's what you need to know when trying to conceive to prepare for the healthiest pregnancy possible."
                  type={1}
                  loc="en"
                />
              </Suspense>
            }
          />
          <Route
            path="/hafta-hamile-gebelik-takibi"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="Hafta hafta hamile ve gebelik takibi, hamilelik belirtileri, hamilelik testi, hamilelikte neler yemeli, nelerden uzak durumalı gibi aklınızdaki bütün sorulara cevap bulabilirsiniz."
                  link="https://www.momercy.com/hafta-hamile-gebelik-takibi"
                  header="Hamilelik / Gebelik Takibi ve Bebek Gelişimi"
                  subHead="Tebrikler ve hamileliğinize hoş geldiniz! Her hafta ne bekleyeceğiniz, doğum için nasıl hazırlanacağınız ve ötesine kadar, işte ihtiyacınız olan bilgiler."
                  type={2}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/week-pregnant-tracking"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="Find answers to all the questions in your mind, such as week-by-week pregnant and pregnancy follow-up, pregnancy symptoms, pregnancy test, what to eat during pregnancy, what to stay away from."
                  link="https://www.momercy.com/en/week-pregnant-tracking"
                  header="Pregnancy / Pregnancy Tracking and Baby Development"
                  loc="en"
                  subHead="Congratulations and welcome to your pregnancy! From what to expect each week to how to prepare for childbirth and beyond, here's the information you need."
                  type={2}
                />
              </Suspense>
            }
          />
          <Route
            path="/dogum-bebek-gelisimi-takibi"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="Hafta hafta bebek gelişimi, beslenmesi, takibi, günlük notlar, anılar, aktiviteler ile sağlikli ve huzurlu annelik."
                  link="https://www.momercy.com/dogum-bebek-gelisimi-takibi"
                  header="Haftalık Bebek Gelişimi Takibi Beslenmesi"
                  subHead="Bebeğiniz için dört gözle beklemeniz gereken her şey, kilometre taşlarından bebeğin büyümesine, beslenmesinden uykusuna ve hayatı kolaylaştıracak ipuçlarına kadar herşey bu kılavuzda."
                  type={3}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/birth-baby-development-tracking"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="Everything you need to look forward to for your baby, from milestones to baby's growth, feeding, sleep and tips to make life easier, is in this guide."
                  link="https://www.momercy.com/en/birth-baby-development-tracking"
                  header="Weekly Baby Development Monitoring Nutrition"
                  subHead="Everything you need to look forward to for your baby, from milestones to baby's growth, feeding, sleep and tips to make life easier, is in this guide."
                  type={3}
                  loc="en"
                />
              </Suspense>
            }
          />
          <Route
            path="/anne-cocuk-gelisimi-takibi"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="Hafta hafta bebek gelişimi, beslenmesi, takibi, günlük notlar, anılar, aktiviteler ile sağlıklı ve huzurlu annelik."
                  link="https://www.momercy.com/anne-cocuk-gelisimi-takibi"
                  header="Çocuk Gelişimi Takibi Beslenmesi"
                  subHead="Güle güle bebeğim, merhaba çocuk! İşte beslenme ve uykudan öğrenme ve davranışa kadar çocukluk yılları için rehberiniz."
                  type={4}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/mother-child-development-follow-up"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="Goodbye baby, hello kid! Here's your guide to the childhood years, from nutrition and sleep to learning and behavior."
                  link="https://www.momercy.com/en/mother-child-development-follow-up"
                  header="Child Development Monitoring Nutrition"
                  subHead="Goodbye baby, hello kid! Here's your guide to the childhood years, from nutrition and sleep to learning and behavior."
                  type={4}
                  loc="en"
                />
              </Suspense>
            }
          />
          <Route
            path="/en/mom-pregnant-chat-community-question-answer"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="Join our community for pregnancy, baby names and meanings, motherhood, cesarean section, development, forum, birth and parent groups and more!"
                  link="https://www.momercy.com/en/mom-pregnant-chat-community-question-answer"
                  header="Question Answer Questionnaire Informationr"
                  subHead="Join our community for baby name ideas, pregnancy, cesarean section, motherhood, development, nutrition, due date discussions, maternity and parent groups and more!"
                  type={5}
                  loc="en"
                />
              </Suspense>
            }
          />
          <Route
            path="/anne-hamile-sohbet-topluluk-soru-cevap"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="Hamilelik, bebek isimleri ve anlamları, annelik, sezaryan, gelişim, forum, doğum ve ebeveyn grupları ve daha fazlası için topluluğumuza katılın!"
                  link="https://www.momercy.com/anne-hamile-sohbet-topluluk-soru-cevap"
                  header="Soru Cevap Anket Bilgiler"
                  subHead="Bebek ismi fikirleri, hamilelik, sezaryan, annelik, gelişim, beslenme, son tarih tartışmaları, doğum ve ebeveyn grupları ve daha fazlası için topluluğumuza katılın!"
                  type={5}
                />
              </Suspense>
            }
          />
          <Route
            path="/anne-cocuk-bebek-urun-onerileri"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="Hamilelik ve bebek takibinde ihtiyaç duyacağınız bebek arabası, hamile yastığı, emzirme, süt pompası ve tüm ürünleri sizin için listeledik."
                  link="https://www.momercy.com/anne-cocuk-bebek-urun-onerileri"
                  header="Hamilelik Öncesi, Hamilelik ve Annelik Serüveni İçin Ürün Önerileri"
                  subHead="Hamilelikten ebeveynliğe konforlu yolculuğunuz için ihtiyaç duyacağınız tüm ürünler burada. Seçimlerimizi nasıl yapıyoruz? Detaylı ürün incelemesi, kullanıcı yorumları analizi ve markanın stratejileri araştırılıp, en favori olanları seçiyoruz. İşte güvenle kullanabileceğiniz ürünler için kılavuzunuz."
                  type={6}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/mother-child-baby-product-recommendations"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="We have listed the stroller, maternity pillow, breastfeeding, breast pump and all the products you will need for pregnancy and baby follow-up."
                  link="https://www.momercy.com/en/mother-child-baby-product-recommendations"
                  header="Product Recommendations for Pre-Pregnancy, Pregnancy and Maternity Adventure"
                  subHead="All the products you will need for your comfortable journey from pregnancy to parenthood are here. How do we make our choices? Detailed product review, analysis of user comments and strategies of the brand are researched and we choose the most favorite ones. Here is your guide to products you can use with confidence."
                  type={6}
                  loc="en"
                />
              </Suspense>
            }
          />
          <Route
            path="/anne-cocuk-bebek-ayricalikli-konular"
            element={
              <Suspense fallback={renderLoader()}>
                <ArticleSubjectsPage
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                  description="Hafta hafta bebek gelişimi, beslenmesi, takibi, günlük notlar, anılar, aktiviteler ile sağlikli ve huzurlu annelik."
                  link="https://www.momercy.com/anne-cocuk-bebek-ayricalikli-konular"
                  header="Çok Yakında"
                  subHead="Evet işte hayat! Çeşitli sürprizler ve bazen de çeşitli zorluklar. Hepsi bizim için. Biz de sizin için rehber olsun diye hazırladık. Küçük bir çocuğunuz varken boşanmayla nasıl başa çıkılır? Prematüre bir bebek bakımı nasıl en iyi yapılır? İkizlere bakmanın kolay yolları var mı? Otizm belirtileri nelerdir? Tüm bu ayrıcalıklı alanlar için burayı ziyaret edebilirsiniz."
                  type={7}
                />
              </Suspense>
            }
          />
          <Route path="/apps" element={<AppStore />} />
          <Route path="/en/apps" element={<AppStore loc="en" />} />
          <Route
            path="/hamilelik-ve-bebek-araclari"
            element={
              <Suspense fallback={renderLoader()}>
                {" "}
                <PregnancyBabyTools
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                />
              </Suspense>
            }
          />
          <Route
            path="/en/pregnancy-and-baby-tools"
            element={
              <Suspense fallback={renderLoader()}>
                <PregnancyBabyTools
                  loc="en"
                  isMobile={isMobile}
                  isNormalScreen={isNormalScreen}
                  isHighScreen={isHighScreen}
                />
              </Suspense>
            }
          />
          <Route path="/ads.txt" onEnter={reload} />
          <Route path="/hamilelik-makaleler.json" />
          <Route path="/hamilelik-makaleler-en.json" />
          <Route path="/hamilelik-semptomlar.json" />
          <Route path="/hamilelik-semptomlar-en.json" />
          <Route path="/first-aid.json" />
          <Route path="/first-aid-en.json" />
          <Route path="/zodiac-akrep.json" />
          <Route path="/zodiac-akrep-en.json" />
          <Route path="/zodiac-aslan.json" />
          <Route path="/zodiac-aslan-en.json" />
          <Route path="/zodiac-balik.json" />
          <Route path="/zodiac-balik-en.json" />
          <Route path="/zodiac-basak.json" />
          <Route path="/zodiac-basak-en.json" />
          <Route path="/zodiac-boga.json" />
          <Route path="/zodiac-boga-en.json" />
          <Route path="/zodiac-ikizler.json" />
          <Route path="/zodiac-ikizler-en.json" />
          <Route path="/zodiac-kid.json" />
          <Route path="/zodiac-kid-en.json" />
          <Route path="/zodiac-koc.json" />
          <Route path="/zodiac-koc-en.json" />
          <Route path="/zodiac-kova.json" />
          <Route path="/zodiac-kova-en.json" />
          <Route path="/zodiac-mom.json" />
          <Route path="/zodiac-mom-en.json" />
          <Route path="/zodiac-oglak.json" />
          <Route path="/zodiac-oglak-en.json" />
          <Route path="/zodiac-terazi.json" />
          <Route path="/zodiac-terazi-en.json" />
          <Route path="/zodiac-yay.json" />
          <Route path="/zodiac-yay-en.json" />
          <Route path="/zodiac-yengec.json" />
          <Route path="/zodiac-yengec-en.json" />
          <Route path="/bebek-makaleler.json" />
          <Route path="/bebek-makaleler-en.json" />
          <Route path="/gebelik-oncesi-makaleler.json" />
          <Route path="/gebelik-oncesi-makaleler-en.json" />
          <Route path="/girl-names.json" />
          <Route path="/boy-names.json" />
          <Route path="/baby-tracker.json" />
          <Route path="/baby-tracker-en.json" />
          <Route path="/pregnancy-tracker.json" />
          <Route path="/pregnancy-tracker-en.json" />
          <Route path="/pregnancy-tracker-en.json" />
          <Route path="/pregnancy-nutrition-en.json" />
          <Route path="/pregnancy-nutrition.json" />
          <Route path="/baby-nutrition-en.json" />
          <Route path="/baby-nutrition.json" />
          <Route path="/baby-activities.json" />
          <Route path="/baby-activities-en.json" />
          <Route path="/app-ads.txt" />
        </Routes>

        {/*<Suspense fallback={renderLoader()}>
          <AppLinkArea
            isMobile={isMobile}
            isNormalScreen={isNormalScreen}
            isHighScreen={isHighScreen}
          />
          </Suspense>*/}
        <div className="public-footer">
          <Suspense fallback={renderLoader()}>
            <Footer isMobile={isMobile} />
          </Suspense>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
