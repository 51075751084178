import Grid from "@mui/material/Grid";
import { useLocation } from "react-router-dom";

export default function Footer(props) {
  const { isMobile } = props;
  const location = useLocation();
  const loc = location?.pathname?.split("/")[1];
  return (
    <div
      className="general-area"
      style={{ backgroundColor: "#442b48", marginTop: 30, marginBottom: 0 }}
    >
      <Grid container alignItems="center" justifyContent="center">
        <Grid
          item
          xs={isMobile ? 11 : 10}
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <img
            loading="lazy"
            data-src="https://amplify-amplify5fdda0668dad4-staging-120746-deployment.s3.eu-central-1.amazonaws.com/momercy/images/logo+for+web.png"
            alt={
              loc === "en"
                ? "Pregnancy Pregnancy Mom Baby App - Momercy"
                : "Hamilelik Takibi Anne ve Bebek Rehberi Uygulaması - Momercy"
            }
            className="head_logo lazyload"
          />
          <div className="dotted_line" />
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={isMobile ? 12 : 3}>
              <h3
                className="general-h2 footer-link"
                style={{
                  textAlign: "left",
                  color: "#f7f9f9",
                  marginBottom: 10,
                  fontSize: 18,
                  fontWeight: "600",
                }}
              >
                {loc === "en"
                  ? "Pregnancy Tracking Baby Development"
                  : "Hamilelik Takibi Bebek Gelişimi"}
              </h3>
              {loc !== "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/hamilelik-ve-bebek-bakimi-momercy"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "600",
                    }}
                  >
                    Annelik Yolculuğunuzda Momercy Yanınızda
                  </h4>
                </a>
              )}
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/birth-baby-development-tracking"
                    : "/populer-erkek-bebek-isimleri"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                >
                  {loc === "en"
                    ? "Baby"
                    : "Popüler Erkek Bebek İsimleri ve Anlamları"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/mother-child-development-follow-up"
                    : "/kuran-da-gecen-erkek-bebek-isimleri"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                >
                  {loc === "en"
                    ? "Kid"
                    : "Kuran`da Geçen Erkek Bebek İsimleri ve Anlamları"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/mom-pregnant-chat-community-question-answer"
                    : "/kuran-da-gecen-kiz-bebek-isimleri"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                >
                  {loc === "en"
                    ? "Community"
                    : "Kuran`da Geçen Kız Bebek İsimleri ve Anlamları"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/mother-child-baby-product-recommendations"
                    : "/kesfedilmemis-erkek-bebek-isimleri"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                >
                  {loc === "en"
                    ? "Product Advices"
                    : "Keşfedilmemiş Erkek Bebek İsimleri ve Anlamları"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/pregnancy-signs-symptoms"
                    : "/kesfedilmemis-kiz-bebek-isimleri"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                >
                  {loc === "en"
                    ? "Pregnancy Symptoms"
                    : "Keşfedilmemiş Kız Bebek İsimleri ve Anlamları"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href="https://www.facebook.com/MomercyPregnancy/"
                target="_blank"
                rel="noreferrer"
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  Facebook - Momercy Pregnancy and Baby
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href="https://www.instagram.com/momercyhamilelik/"
                target="_blank"
                rel="noreferrer"
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  Instagram - Momercy Pregnancy and Baby Tracker
                </h4>
              </a>
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <h3
                className="general-h2 footer-link"
                style={{
                  textAlign: "left",
                  color: "#f7f9f9",
                  marginBottom: 10,
                  fontSize: 18,
                  fontWeight: "600",
                }}
              >
                {loc === "en"
                  ? "Pregnancy Special Pages"
                  : "Hamilelik Özel Sayfalar"}
              </h3>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/pregnancy-planning-ovulation"
                    : "/hamilelik-planlama-ovulasyon"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                >
                  {loc === "en" ? "Pregnancy Planning" : "Hamilelik Planlama"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/pregnancy-massage"
                    : "/hamilelikte-saglikli-yasam-egzersiz"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  {loc === "en"
                    ? "Pregnancy Massage"
                    : "Hamilelikte Sağlıklı Yaşam ve Egzersiz"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/pregnancy-signs-symptoms/46"
                    : "/tup-bebek-gebelik-hesaplama"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  {loc === "en"
                    ? "Bleeding While Pregnant"
                    : "Tüp Bebek Gebelik Hesaplama"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/pregnancy-signs-symptoms/82"
                    : "/dogum-cantasi-hazirligi"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  {loc === "en"
                    ? "Decreased Sex Drive During Pregnancy"
                    : "Doğum Çantası Hazırlığı: Gerekli Eşyalar ve İpuçları"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/pregnancy-signs-symptoms/66"
                    : "/makaleler/hafta-hamile-gebelik-takibi/saglik/MT-0-61"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  {loc === "en"
                    ? "Morning Sickness During Pregnancy"
                    : "Hamilelik Testi - Gebelik Testi"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/articles/week-pregnant-tracking/health/MT-0-35"
                    : "/butun-tuhaf-hamilelik-belirtileri"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  {loc === "en"
                    ? "Heartburn During Pregnancy"
                    : "Tuhaf Hamilelik Belirtileri"}
                </h4>
              </a>
              {loc !== "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/hamilelik-ve-gebelik-takibi-saglikli"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Hamilelik ve Gebelik Takibi: Sağlıklı Bir Yolculuğun
                    Anahtarları
                  </h4>
                </a>
              )}
              {loc !== "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/hamilelikte-gun-gun-bebek-gelisimi"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Hamilelikte Gün Gün Bebek Gelişimi
                  </h4>
                </a>
              )}
              {loc !== "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/makaleler/hafta-hamile-gebelik-takibi/saglik/MT-0-57"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Hamilelikte erkek bebek belirtileri nelerdir?
                  </h4>
                </a>
              )}
              {loc !== "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/makaleler/hafta-hamile-gebelik-takibi/saglik/MT-0-58"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Hamilelikte kız bebek belirtileri nelerdir?
                  </h4>
                </a>
              )}
              {loc === "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/en/are-pregnancy-tests-accurate"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Are Pregnancy Tests Accurate?
                  </h4>
                </a>
              )}
              {loc === "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/en/pregnancy-discharge"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Pregnancy Discharge
                  </h4>
                </a>
              )}
              {loc === "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/en/pregnancy-dreams"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Pregnancy Dreams
                  </h4>
                </a>
              )}
              {loc === "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/en/pregnancy-due-date-calculator"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Pregnancy Due Date Calculator
                  </h4>
                </a>
              )}
              {loc === "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/en/pregnancy-early-symptoms"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Pregnancy Early Symptoms
                  </h4>
                </a>
              )}
              {loc === "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/en/pregnancy-insomnia"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Pregnancy Insomnia
                  </h4>
                </a>
              )}
              {loc === "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/en/pregnancy-journal"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Pregnancy Journal
                  </h4>
                </a>
              )}
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
              <h3
                className="general-h2 footer-link"
                style={{
                  textAlign: "left",
                  color: "#f7f9f9",
                  marginBottom: 10,
                  fontSize: 18,
                  fontWeight: "600",
                }}
              >
                {loc === "en" ? "Baby Special Pages" : "Bebek Özel Sayfalar"}
              </h3>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/pregnancy-and-baby-tools"
                    : "/hamilelik-ve-bebek-araclari"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                >
                  {loc === "en" ? "Tools" : "Hamilelik ve Bebek Takibi"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/month-month-baby-development-tracking-checklist"
                    : "/ay-ay-bebek-gelisimi-takibi-kontrol-listesi"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  {loc === "en"
                    ? "Month by Month Baby Checklist Tracking"
                    : "Ay Ay Bebek Kontrol Listesi Takibi"}
                </h4>
              </a>
              {loc !== "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={"/bebeklerde-gaz-sancısına-neler-iyi-gelir"}
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Bebeklerde gaz sancısına neler iyi gelir?
                  </h4>
                </a>
              )}
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/week-week-baby-development-tracking"
                    : "/hafta-hafta-bebek-gelisimi-takibi"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  {loc === "en"
                    ? "Week by Week Baby Development"
                    : "Hafta Hafta Bebek Gelişimi"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/week-week-baby-development-activities"
                    : "/hafta-hafta-bebek-gelisimi-aktiviteleri"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  {loc === "en"
                    ? "Week by Week Baby Activities"
                    : "Hafta Hafta Bebek Aktiviteleri"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/month-month-baby-nutrition"
                    : "/ay-ay-bebek-beslenme"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  {loc === "en"
                    ? "Baby Feeding Month by Month"
                    : "Ay Ay Bebek Beslenmesi"}
                </h4>
              </a>
              {loc !== "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/bebek-sik-sorulan-sorular"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Göbek Bağı Ne Zaman Düşer?
                  </h4>
                </a>
              )}
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={
                  loc === "en"
                    ? "/en/mother-baby-zodiac-compatibility-calculation"
                    : "/anne-bebek-burc-uyumu-hesaplama"
                }
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  {loc === "en"
                    ? "Mother Baby Child Horoscope Compatibility Calculator"
                    : "Anne Bebek Çocuk Burç Uyumu Hesaplama"}
                </h4>
              </a>
              <a
                className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                href={loc === "en" ? "/en/baby-first-aid" : "/bebek-ilk-yardim"}
              >
                <h4
                  className="general-h2"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  {loc === "en"
                    ? "First Aid for Babies and Young Children"
                    : "Bebekler ve Küçük Çocuklar İçin İlk Yardım"}
                </h4>
              </a>
              {loc !== "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/dogum-bebek-gelisimi-takibi/beslenme/MT-2-40"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Bebeklerde Demir Eksikliği İçin Beslenme İpuçları
                  </h4>
                </a>
              )}
              {loc !== "en" && (
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/dogum-bebek-gelisimi-takibi/saglik/MT-1-32"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    En İyi Bebek Demir İlacı ve Bebek D vitamini
                  </h4>
                </a>
              )}
            </Grid>
            {loc !== "en" && (
              <Grid item xs={isMobile ? 12 : 3}>
                <h3
                  className="general-h2 footer-link"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 18,
                    fontWeight: "600",
                  }}
                >
                  Hamile ve Bebek Ürün Önerileri
                </h3>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/anne-cocuk-bebek-urun-onerileri/anne/MT-0-21"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    En İyi Anne Bebek Bakım Çantaları
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/anne-cocuk-bebek-urun-onerileri/hamilelik/MT-0-6"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    En İyi Bebek Arabası
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/anne-cocuk-bebek-urun-onerileri/anne/MT-0-25"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    En İyi Bebek Beşikleri
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/anne-cocuk-bebek-urun-onerileri/anne/MT-0-5"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    En İyi Bebek Çamaşır Deterjanları
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/anne-cocuk-bebek-urun-onerileri/anne/MT-0-12"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    En İyi Mama Sandalyeleri
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/anne-cocuk-bebek-urun-onerileri/anne/MT-0-28"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    En İyi Bebek Maması Markaları
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/anne-cocuk-bebek-urun-onerileri/anne/MT-0-24"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    En İyi Bebek Bezi
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/anne-cocuk-bebek-urun-onerileri/anne/MT-0-30"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    En İyi Bebek Şampuanları ve Sabunları
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/anne-cocuk-bebek-urun-onerileri/anne/MT-0-39"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Ahşap Oyuncaklar
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/anne-cocuk-bebek-urun-onerileri/anne/MT-0-40"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Bebek Islak Mendili
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/makaleler/anne-cocuk-bebek-urun-onerileri/anne/MT-0-38"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    Bebek Uyku Seti ve Nevresim Takımları
                  </h4>
                </a>
              </Grid>
            )}
            {loc === "en" && (
              <Grid item xs={isMobile ? 12 : 3}>
                <h3
                  className="general-h2 footer-link"
                  style={{
                    textAlign: "left",
                    color: "#f7f9f9",
                    marginBottom: 10,
                    fontSize: 18,
                    fontWeight: "600",
                  }}
                >
                  Pregnant and Baby Product Recommendations
                </h3>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/en/articles/mother-child-baby-product-recommendations/pregnancy/MT-0-3"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    The Best Pregnancy Belly and Support Belts
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/en/articles/mother-child-baby-product-recommendations/pregnancy/MT-0-11"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    The Best Baby Formulas
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/en/articles/mother-child-baby-product-recommendations/pregnancy/MT-0-14"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    The Best Pregnancy Tests
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/en/articles/mother-child-baby-product-recommendations/mom/MT-0-1"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    What Is The Best Nipple Cream?
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/en/articles/mother-child-baby-product-recommendations/mom/MT-0-11"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    The Best Baby Teething Toys
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/en/articles/mother-child-baby-product-recommendations/mom/MT-0-12"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    The Best Highchairs
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/en/articles/mother-child-baby-product-recommendations/mom/MT-0-18"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    The best baby car seats
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/en/articles/mother-child-baby-product-recommendations/mom/MT-0-16"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    The Best Strollers
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/en/articles/mother-child-baby-product-recommendations/mom/MT-0-30"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    The Best Video Baby Monitors
                  </h4>
                </a>
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href="/en/articles/mother-child-baby-product-recommendations/mom/MT-0-28"
                >
                  <h4
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      color: "#f7f9f9",
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "400",
                    }}
                  >
                    The Best Baby Food Sets, Bowls And Plates
                  </h4>
                </a>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Grid
          item
          xs={isMobile ? 11 : 10}
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <div className="dotted_line" />
          {loc === "en" ? (
            <h5 className="general-p2" style={{ color: "#f7f9f9" }}>
              During pregnancy follow-up and baby development; The `Momercy`
              application, where you can learn about pregnancy and pregnancy
              symptoms, do a pregnancy test, see the baby carriage, pregnant
              pillow and all the products you will need during pregnancy and
              baby development, does not aim to replace the needs advice of a
              mother or expectant mother while raising her baby. For more
              information
              <a
                href="mailto:bilgi@momercy.com"
                className="footer-link"
                target="_blank"
                rel="noreferrer"
              >
                <span
                  className="text"
                  style={{ color: "#f7f9f9", fontWeight: "900" }}
                >
                  {loc === "en" ? " Contact us." : " Bize Ulaşın."}
                </span>
              </a>
            </h5>
          ) : (
            <h5 className="general-p2" style={{ color: "#f7f9f9" }}>
              Hamilelik takibi ve bebek gelişim sürecinde;{" "}
              <a
                className="footer-link"
                href="yuzde-yuz-hamilelik-belirtileri-nelerdir"
                target="_blank"
              >
                hamilelik ve gebelik belirtilerini
              </a>{" "}
              öğrenebileceğiniz,{" "}
              <a
                className="footer-link"
                href="hamile-miyim-test-yapmak"
                target="_blank"
              >
                hamilelik testi
              </a>{" "}
              yapabileceğiniz, hamilelikte ve bebek gelişiminde ihtiyaç
              duyacağınız{" "}
              <a
                className="footer-link"
                href="makaleler/anne-cocuk-bebek-urun-onerileri/hamilelik/MT-0-6"
                target="_blank"
              >
                bebek arabası
              </a>{" "}
              , hamile yastığı ve tüm ürünleri görebileceğiniz "Momercy"
              uygulaması bir annenin ya da anne adaylarının bebeğini büyütürken
              ihtiyaç tavsiyelerinin yerini alma amacı taşımamaktadır.
            </h5>
          )}
          <p style={{ color: "#fff" }}>
            <a
              style={{ color: "#fff" }}
              href={loc === "en" ? "/en/about-us" : "/hakkimizda"}
              target="_blank"
              rel="noreferrer"
            >
              {loc === "en" ? "About Us" : "Hakkımızda"}
            </a>
            ,
            <a
              style={{ color: "#fff" }}
              href={loc === "en" ? "en/terms-conditions" : "kullanim-kosullari"}
              target="_blank"
              rel="noreferrer"
            >
              {loc === "en" ? "Term Conditions" : "Kullanım Koşulları"}
            </a>
            ,
            <a
              style={{ color: "#fff" }}
              href={loc === "en" ? "en/privacy-policy" : "privacy-policy"}
              target="_blank"
              rel="noreferrer"
            >
              {loc === "en" ? "Privacy Policy" : "Gizlilik Politikası"}
            </a>
            ,
            <a
              style={{ color: "#fff" }}
              href={loc === "en" ? "en/contact" : "iletisim"}
              target="_target"
            >
              {loc === "en" ? "Contact" : "İletişim"}
            </a>{" "}
            için bu linkleri kullanabilirsiniz.
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ padding: 20 }}
      >
        <Grid item>
          <p className="general-p2" style={{ color: "#f7f9f9" }}>
            Created by{" "}
            <a
              className="footer-link"
              href="https://www.ocalibus.com"
              target="_blank"
              rel="noreferrer"
            >
              Ocalibus
            </a>{" "}
            {loc === "en"
              ? "I © 2024 All rights reserved."
              : "I © 2024 Tüm hakları saklıdır."}
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
