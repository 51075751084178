import React, { useEffect } from "react";
import HomePage from "./HomePage";
import { Helmet } from "react-helmet";

function AppStore(props) {
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 0;
    }

    if (/android/i.test(userAgent)) {
      return 1;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 2;
    }

    return 0;
  }

  useEffect(() => {
    const val = getMobileOperatingSystem();
    if (val === 1) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.annebebek.annemindunyasi";
      /*try {
        logEvent(analytics, 'click_app_link', {
          mobile: 'android'
        });
      } catch (error) {
        
      }*/
    } else if (val === 2) {
      window.location.href =
        "https://apps.apple.com/us/app/mom-talks-bebek-geli%C5%9Fimi/id1447682983";
      /*try {
        logEvent(analytics, 'click_app_link', {
          mobile: 'ios'
        });
      } catch (error) {
      }*/
    } else {
      window.location.href =
        props.loc === "en"
          ? "https://www.momercy.com/en"
          : "https://www.momercy.com";
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Hamilelik takibi ve gebelik takibi, hamilelikte beslenme, persentil hesaplama, kız erkek bebek isimleri, regl takvimi, anneler için bebek gelişimi uygulaması."
        />
        <link rel="canonical" href="https://www.momercy.com" />
      </Helmet>
      <HomePage />
    </>
  );
}

export default AppStore;
