import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";

import "../css/Articles.css";
//import { getDatabase, ref, child, get } from "firebase/database";
import { IconContext } from "react-icons";
import { MdKeyboardArrowRight } from "react-icons/md";

import { CustomButton } from "../tools/CustomButton";
import { sendLogEvent } from "../fConfiguration";
import ScaleLoader from "react-spinners/ScaleLoader";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

/*const dbRef = ref(getDatabase());
get(child(dbRef, `Community/Messages/forum`)).then((snapshot) => {
  if (snapshot.exists()) {
    const {hamilelik,bebek_istiyoruz} = snapshot.val()
    let array = []
    let topic_count = 0
    for (var property in bebek_istiyoruz) {
      let obj = {
        topic: property,
        messages: [],
        topic_count,
        message: ""
      }
      for (var pro1 in bebek_istiyoruz[property]) {
        for (var pro2 in bebek_istiyoruz[property][pro1]) {
          const {date, message, name} =  bebek_istiyoruz[property][pro1][pro2]
          const child_obj = {date, message, name}
          obj.messages.push(child_obj)
        }
        get(child(dbRef, `Community/Subjects/forum/bebek_istiyoruz/${property}`)).then((snapshot2) => {
          if (snapshot2.exists()) {
            const sub_val = snapshot2.val()
            for (var sub_pro1 in sub_val) {
              if (sub_pro1 === pro1) {
                obj.message = sub_val[sub_pro1].message
              }
            }
          }
        })
      }
      array.push(obj)
      topic_count += 1
    }
    console.log(array)

    let array2 = []
    let topic_count2 = 0
    for (var property in hamilelik) {
      let obj = {
        topic: property,
        messages: [],
        topic_count2,
        message: ""
      }
      for (var pro1 in hamilelik[property]) {
        for (var pro2 in hamilelik[property][pro1]) {
          const {date, message, name} =  hamilelik[property][pro1][pro2]
          const child_obj = {date, message, name}
          obj.messages.push(child_obj)
        }
        get(child(dbRef, `Community/Subjects/forum/hamilelik/${property}`)).then((snapshot2) => {
          if (snapshot2.exists()) {
            const sub_val = snapshot2.val()
            for (var sub_pro1 in sub_val) {
              if (sub_pro1 === pro1) {
                obj.message = sub_val[sub_pro1].message
              }
            }
          }
        })
      }
      array2.push(obj)
      topic_count2 += 1
    }
    console.log("array2", array2)
  } else {
    console.log("No data available");
  }
}).catch((error) => {
  console.error(error);
});*/

const ArticleProducts = lazy(() => import("../component/ArticleProducts"));
const LazyImage = lazy(() => import("../component/LazyImage"));
const HomePagePopularTools = lazy(() =>
  import("../component/HomePagePopularTools")
);
const HomePageArticles = lazy(() => import("../component/HomePageArticles"));
const HomePageSlider = lazy(() => import("../component/HomePageSlider"));
const HomePageLastestArticles = lazy(() =>
  import("../component/HomePageLastestArticles")
);
const PB_HomePage_Pregnancy_Weeks = lazy(() =>
  import("../component/PB_HomePage_Pregnancy_Weeks")
);
const PB_HomePage_Baby_Weeks = lazy(() =>
  import("../component/PB_HomePage_Baby_Weeks")
);

function HomePage(props) {
  const { isMobile, isNormalScreen, isHighScreen, loc } = props;
  const [linkProduct, setLinkProduct] = React.useState("HAMİLELİK");
  const [linkLatestArticle, setLinkLatestArticle] = React.useState("HAMİLELİK");

  const [expanded, setExpanded] = React.useState("pregnancy");

  const clickCustomButton = (link) => {
    window.open(link, "_self");
  };

  const allButton = (link = "") => {
    return (
      <Grid item>
        <CustomButton
          onClick={() =>
            clickCustomButton(
              link
                ? link
                : `/${
                    expanded === "pregnancy"
                      ? loc === "en"
                        ? "en/week-pregnant-tracking"
                        : "hafta-hamile-gebelik-takibi"
                      : expanded === "baby"
                      ? loc === "en"
                        ? "en/birth-baby-development-tracking"
                        : "dogum-bebek-gelisimi-takibi"
                      : expanded === "kid"
                      ? loc === "en"
                        ? "en/mother-child-development-follow-up"
                        : "anne-cocuk-gelisimi-takibi"
                      : loc === "en"
                      ? "en/pregnancy-planning-ovulation"
                      : "hamilelik-planlama-ovulasyon"
                  }`
            )
          }
        >
          {loc === "en" ? "See All" : "Hepsini Gör"}
        </CustomButton>
      </Grid>
    );
  };

  const renderLoader = () => (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        height: "50vmax",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ScaleLoader
        color={"#a4a4ff"}
        width={20}
        height={50}
        radius={30}
        margin={4}
      />
    </div>
  );

  const openInNewTab = (url) => {
    sendLogEvent("click_mobile_market_link");
    window.open(url, "_blank", "noopener,noreferrer");
  };

  if (loc === "en") {
    document.documentElement.lang = "en";
  }

  const LazySection = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef();

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Bölüm yüklendikten sonra gözlemlemeyi durdurur
          }
        },
        {
          rootMargin: "0px",
          threshold: 0.1, // %10'u görünür olduğunda yükle
        }
      );

      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) observer.unobserve(sectionRef.current);
      };
    }, []);

    return (
      <div ref={sectionRef}>
        {isVisible ? (
          <Grid
            display="flex"
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={isMobile || isNormalScreen ? 11 : isHighScreen ? 9 : 7}
            >
              <h3
                className="general-h2"
                style={{ textAlign: "left", marginTop: isMobile ? 10 : 20 }}
              >
                {loc === "en"
                  ? "Product Recommendations in Pregnancy and Maternity"
                  : "Hamilelik ve Annelikte Ürün Önerileri"}
              </h3>
            </Grid>
            <Grid
              style={{ marginTop: 10 }}
              item
              xs={isMobile || isNormalScreen ? 11 : isHighScreen ? 9 : 7}
            >
              <Grid
                display="flex"
                container
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <CustomButton
                    borderColor="#643368"
                    padding={"10px"}
                    borderWidth={"1px solid"}
                    boxShadow="0px"
                    color={linkProduct === "HAMİLELİK" ? "#ffffff" : "#643368"}
                    backgroundColor={
                      linkProduct === "HAMİLELİK" ? "#643368" : "transparent"
                    }
                    fontSize={16}
                    marginRight={15}
                    marginTop={5}
                    marginBottom={5}
                    onClick={() => setLinkProduct("HAMİLELİK")}
                  >
                    {loc === "en" ? "PREGNANCY" : "HAMİLELİK"}
                  </CustomButton>
                  <CustomButton
                    borderColor="#643368"
                    padding={"10px"}
                    borderWidth={"1px solid"}
                    boxShadow="0px"
                    color={linkProduct === "BEBEK" ? "#ffffff" : "#643368"}
                    backgroundColor={
                      linkProduct === "BEBEK" ? "#643368" : "transparent"
                    }
                    fontSize={16}
                    marginRight={15}
                    marginTop={5}
                    marginBottom={5}
                    onClick={() => setLinkProduct("BEBEK")}
                  >
                    {loc === "en" ? "BABY" : "BEBEK"}
                  </CustomButton>
                </Grid>
                {linkProduct === "HAMİLELİK" && (
                  <Suspense fallback={renderLoader()}>
                    <ArticleProducts
                      loc={loc}
                      isMobile={isMobile}
                      isPregnancy={true}
                    />
                  </Suspense>
                )}
                {linkProduct === "BEBEK" && (
                  <Suspense fallback={renderLoader()}>
                    <ArticleProducts
                      loc={loc}
                      isMobile={isMobile}
                      isPregnancy={false}
                    />
                  </Suspense>
                )}
              </Grid>
              <Grid
                style={{ marginTop: 10 }}
                display="flex"
                container
                alignItems="center"
                justifyContent="center"
              >
                {allButton(
                  `https://www.momercy.com/${
                    loc === "en"
                      ? "en/mother-child-baby-product-recommendations"
                      : "anne-cocuk-bebek-urun-onerileri"
                  }/${linkProduct === "HAMİLELİK" ? "hamilelik" : "anne"}`
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const LazySection2 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef();

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Bölüm yüklendikten sonra gözlemlemeyi durdurur
          }
        },
        {
          rootMargin: "0px",
          threshold: 0.1, // %10'u görünür olduğunda yükle
        }
      );

      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) observer.unobserve(sectionRef.current);
      };
    }, []);

    return (
      <div ref={sectionRef}>
        {isVisible ? (
          <Suspense fallback={renderLoader()}>
            <HomePageLastestArticles
              isMobile={isMobile}
              isNormalScreen={isNormalScreen}
              isHighScreen={isHighScreen}
              expanded={
                linkLatestArticle === "HAMİLELİK" ? "pregnancy" : "baby"
              }
              linkLatestArticle={linkLatestArticle}
              loc={loc}
              onClick={(it) => setLinkLatestArticle(it)}
            />
          </Suspense>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const LazySection3 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef();

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Bölüm yüklendikten sonra gözlemlemeyi durdurur
          }
        },
        {
          rootMargin: "0px",
          threshold: 0.1, // %10'u görünür olduğunda yükle
        }
      );

      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) observer.unobserve(sectionRef.current);
      };
    }, []);

    return (
      <div ref={sectionRef}>
        {isVisible ? (
          <Suspense fallback={renderLoader()}>
            <PB_HomePage_Baby_Weeks
              isMobile={isMobile}
              isNormalScreen={isNormalScreen}
              isHighScreen={isHighScreen}
              loc={loc}
            />
          </Suspense>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const LazySection4 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef();

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Bölüm yüklendikten sonra gözlemlemeyi durdurur
          }
        },
        {
          rootMargin: "0px",
          threshold: 0.1, // %10'u görünür olduğunda yükle
        }
      );

      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) observer.unobserve(sectionRef.current);
      };
    }, []);

    return (
      <div ref={sectionRef}>
        {isVisible ? (
          <Suspense fallback={renderLoader()}>
            <HomePageArticles
              loc={loc}
              isMobile={isMobile}
              isNormalScreen={isNormalScreen}
              isHighScreen={isHighScreen}
              expanded={expanded}
            />
          </Suspense>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const LazySection5 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef();

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Bölüm yüklendikten sonra gözlemlemeyi durdurur
          }
        },
        {
          rootMargin: "0px",
          threshold: 0.1, // %10'u görünür olduğunda yükle
        }
      );

      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) observer.unobserve(sectionRef.current);
      };
    }, []);

    return (
      <div ref={sectionRef}>
        {isVisible ? (
          <Suspense fallback={renderLoader()}>
            <PB_HomePage_Pregnancy_Weeks
              isMobile={isMobile}
              isNormalScreen={isNormalScreen}
              isHighScreen={isHighScreen}
              loc={loc}
            />
          </Suspense>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const LazySection6 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef();

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Bölüm yüklendikten sonra gözlemlemeyi durdurur
          }
        },
        {
          rootMargin: "0px",
          threshold: 0.1, // %10'u görünür olduğunda yükle
        }
      );

      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) observer.unobserve(sectionRef.current);
      };
    }, []);

    return (
      <div ref={sectionRef}>
        {isVisible ? (
          <Suspense fallback={renderLoader()}>
            <HomePagePopularTools
              isMobile={isMobile}
              isNormalScreen={isNormalScreen}
              isHighScreen={isHighScreen}
              expanded={expanded}
              loc={loc}
            />
          </Suspense>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const LazySection7 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef();

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Bölüm yüklendikten sonra gözlemlemeyi durdurur
          }
        },
        {
          rootMargin: "0px",
          threshold: 0.1, // %10'u görünür olduğunda yükle
        }
      );

      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) observer.unobserve(sectionRef.current);
      };
    }, []);

    return (
      <div ref={sectionRef}>
        {isVisible ? (
          <Grid
            item
            xs={isMobile ? 12 : null}
            style={{ width: !isMobile ? "48%" : null }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{ height: "100%" }}
            >
              <Grid
                item
                xs={12}
                className="shadow-area-now"
                style={{
                  backgroundColor: "#fff",
                  height: !isMobile ? 480 : null,
                  marginBottom: isMobile ? 10 : 30,
                  marginTop: 10,
                  marginRight: isMobile ? 5 : 20,
                  marginLeft: isMobile ? 5 : 20,
                }}
              >
                <a
                  className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                  href={
                    loc === "en"
                      ? "/en/pregnancy-nutrition-health"
                      : "/hafta-hafta-bebek-gelisimi/16-hafta"
                  }
                >
                  <LazyImage
                    head={
                      loc === "en"
                        ? "Nutrition During Pregnancy"
                        : "16. Hafta Bebek Gelişimi Tablosu Takibi"
                    }
                    image={
                      loc === "en"
                        ? "https://amplify-amplify5fdda0668dad4-staging-120746-deployment.s3.eu-central-1.amazonaws.com/momercy/images/hamilelikte_beslenme.webp"
                        : "https://amplify-amplify5fdda0668dad4-staging-120746-deployment.s3.eu-central-1.amazonaws.com/momercy/images/11_50.webp"
                    }
                    style={{
                      width: "100%",
                      height: "60%",
                      marginBottom: 20,
                    }}
                    isMobile={isMobile}
                  />
                  <h3 className="general-h3" style={{ fontSize: 16 }}>
                    {loc === "en"
                      ? "NUTRITION IN PREGNANCY"
                      : "HAMİLELİKTE SAĞLIK"}
                  </h3>
                  <p
                    className="general-h2"
                    style={{
                      textAlign: "left",
                      marginTop: 5,
                      marginBottom: 5,
                      fontSize: 20,
                      fontWeight: "900",
                    }}
                  >
                    {loc === "en"
                      ? "Nutrition and Health in Pregnancy Month by Month"
                      : "16. Hafta Bebek Gelişimi Tablosu Takibi"}
                  </p>
                  <p className="general-p2">
                    {loc === "en"
                      ? "It can be difficult to stick to the rules of weight gain during pregnancy."
                      : "Dört ayınız kutlu olsun! Bu, genellikle ebeveynlerin çoğunun bebeklerinin nasıl bu kadar hızlı büyüdüğüne şaşırdıkları dönemdir."}
                  </p>
                </a>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const LazySection8 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef();

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Bölüm yüklendikten sonra gözlemlemeyi durdurur
          }
        },
        {
          rootMargin: "0px",
          threshold: 0.1, // %10'u görünür olduğunda yükle
        }
      );

      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) observer.unobserve(sectionRef.current);
      };
    }, []);

    return (
      <div ref={sectionRef}>
        {isVisible ? (
          <Grid
            item
            xs={isMobile ? 12 : null}
            style={{ width: !isMobile ? "28%" : null }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{ height: "100%" }}
            >
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: isMobile ? 10 : 30,
                  backgroundColor: "#fff",
                  height: !isMobile ? 480 : null,
                  marginTop: 10,
                  marginRight:
                    isMobile || isNormalScreen || isHighScreen ? 5 : 5,
                  marginLeft:
                    isMobile || isNormalScreen || isHighScreen ? 5 : 5,
                  padding: 0,
                }}
                className="shadow-area-now"
              >
                <h3
                  className="general-p1"
                  style={{
                    fontSize: 24,
                    textAlign: "center",
                    color: "#ffffff",
                    backgroundColor: "#416165",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  {loc === "en" ? "Trends" : "Trendler"}
                </h3>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    height: !isMobile ? 420 : null,
                    padding: 20,
                    paddingBottom: 10,
                    paddingTop: 0,
                  }}
                >
                  <Grid item xs={12} style={{ marginTop: 3, marginBottom: 3 }}>
                    <a
                      className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                      href={
                        loc === "en"
                          ? "/en/articles/week-pregnant-tracking/baby/MT-5-4"
                          : "/hamilelikte-ilk-uc-ay-belirtiler"
                      }
                    >
                      <p
                        className="general-h2"
                        style={{
                          textAlign: "left",
                          fontSize: 20,
                          fontWeight: "900",
                        }}
                      >
                        {loc === "en"
                          ? "Week by Week Baby Movements"
                          : "Hamilelikte İlk Üç Ay"}
                      </p>
                      <p className="general-p2">
                        {loc === "en"
                          ? "Baby movements start from the 7th week."
                          : "Hamileliğin ilk üç ayı, birçok değişiklik ve heyecan verici gelişmelerin yaşandığı bir dönemdir."}
                      </p>
                    </a>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 3, marginBottom: 3 }}>
                    <a
                      className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                      href={
                        loc === "en"
                          ? "/en/pregnancy-signs-symptoms/21"
                          : "/hamilelikte-16-hafta-bebegin-gelisimi"
                      }
                    >
                      <p
                        className="general-h2"
                        style={{
                          textAlign: "left",
                          fontSize: 20,
                          fontWeight: "900",
                        }}
                      >
                        {loc === "en"
                          ? "Change in Vaginal Discharge During Pregnancy"
                          : "Hamilelikte 16. Hafta: Bebeğinizin Gelişimi ve Annedeki Değişiklikler"}
                      </p>
                      <p className="general-p2">
                        {loc === "en"
                          ? "In this case, it's a good idea to call your healthcare provider."
                          : "Hamileliğinizin 16. haftasına hoş geldiniz! "}
                      </p>
                    </a>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 3, marginBottom: 3 }}>
                    <a
                      className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                      href={
                        loc === "en"
                          ? "/en/articles/week-pregnant-tracking/health/MT-0-6"
                          : "/makaleler/hafta-hamile-gebelik-takibi/saglik/MT-0-59"
                      }
                    >
                      <p
                        className="general-h2"
                        style={{
                          textAlign: "left",
                          fontSize: 20,
                          fontWeight: "900",
                        }}
                      >
                        {loc === "en"
                          ? "When can I take a pregnancy test?"
                          : "Cinsiyet Tahmini İçin Tüm Yöntemler"}
                      </p>
                      <p className="general-p2">
                        {loc === "en"
                          ? "There are two types of pregnancy tests: urine test and blood test."
                          : "Geleneksel birçok yöntemle cinsiyet tahmini yapmak gerçekten oldukça eğlenceli olacak."}
                      </p>
                    </a>
                  </Grid>
                  {/*<Grid
                  item
                  xs={12}
                  style={{ marginTop: 3, marginBottom: 3 }}
                >
                  <a
                    className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                    href="/hamilelik-gebelik-belirtileri-semptomlar/46"
                  >
                    <p
                      className="general-h2"
                      style={{
                        textAlign: "left",
                        fontSize: 20,
                        fontWeight: "900",
                      }}
                    >
                      Hamilelikte Vajinal Kanama
                    </p>
                    <p className="general-p2">
                      Hamileyken adet döneminiz bitmiyor mu?
                    </p>
                  </a>
                    </Grid>*/}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  return (
    <div className="mainRoot">
      <Helmet>
        <meta
          property="og:site_name"
          content={
            loc === "en"
              ? "Pregnancy Tracking, Baby Development Center Week by Week, Mother Application"
              : "Hamilelik Takibi, Bebek Gelişimi, Anne Uygulaması, Kız Erkek Bebek isimleri ve anlamları"
          }
        />
        <meta
          property="og:url"
          content={
            loc === "en"
              ? "https://www.momercy.com/en"
              : "https://www.momercy.com/"
          }
        />
        <meta property="og:locale" content={loc === "en" ? "en_EN" : "tr_TR"} />
        <meta
          name="title"
          content={
            loc === "en"
              ? "Pregnancy Tracking, Baby Development Center Week by Week, Mother Application"
              : "Hamilelik Takibi, Bebek Gelişimi, Anne Uygulaması, Kız Erkek Bebek isimleri ve anlamları"
          }
        />
        <title>
          {props.loc === "en"
            ? "Pregnancy Tracking, Baby Development and Names, Mother Application"
            : "Hafta Hafta Hamilelik Takibi, Bebek Gelişimi Uygulaması"}
        </title>
        <meta
          name="description"
          content={
            loc === "en"
              ? "Pregnancy Symptoms and Tracking, Pregnancy test, baby girl names, ovulation menstrual calendar, baby development app week by week for mothers and candidates, baby center."
              : "Hamilelik belirtileri,takibi ve testi,kız erkek bebek isimleri ve anlamları,doğum tarihi,persentil hesaplama,anneler için haftalık bebek gelişimi uygulaması."
          }
        />
        <meta
          property="og:title"
          content={
            loc === "en"
              ? "Pregnancy Tracking and Baby Development, Baby Center"
              : "Hamilelik Takibi ve Bebek Gelişimi"
          }
        />
        <meta
          property="og:description"
          content={
            loc === "en"
              ? "Pregnancy and pregnancy follow-up, nutrition during pregnancy, baby girl names, ovulation menstrual calendar, baby development application for mothers."
              : "Hamilelik ve gebelik sürecinde takip, hamilelikte beslenme, kız erkek bebek isimleri, yumurtlama regl takvimi, anneler için bebek gelişimi uygulaması."
          }
        />
        <meta
          name="twitter:title"
          content={
            loc === "en"
              ? "Pregnancy Tracking and Baby Development, Baby Center"
              : "Hamilelik Takibi ve Bebek Gelişimi"
          }
        />
        <meta
          name="twitter:description"
          content={
            loc === "en"
              ? "Pregnancy and pregnancy follow-up, nutrition during pregnancy, baby girl names, ovulation menstrual calendar, baby center, baby development application for mothers."
              : "Hamilelik ve gebelik sürecinde takip, hamilelikte beslenme, kız erkek bebek isimleri, yumurtlama regl takvimi, anneler için bebek gelişimi uygulaması."
          }
        />
        <link
          rel="canonical"
          href={
            loc === "en"
              ? "https://www.momercy.com/en"
              : "https://www.momercy.com"
          }
        />
      </Helmet>

      {!isMobile && (
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 0,
            width: "100%",
            backgroundColor: "#442b48",
          }}
        >
          <Grid
            display="flex"
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={isMobile ? 11 : isNormalScreen ? 11 : isHighScreen ? 9 : 7}
            >
              <Grid
                display="flex"
                container
                alignItems="center"
                justifyContent="center"
              >
                <Grid
                  item
                  xs={isMobile ? 12 : null}
                  style={{ width: !isMobile ? "45%" : null }}
                >
                  <h1
                    className="font-merriweather"
                    style={{ color: "#ffffff", fontSize: 18 }}
                  >
                    {loc === "en"
                      ? "Learn, grow together with Pregnancy Symptoms and Pregnancy Tracker, Baby Development App, Week by Week."
                      : "Momercy: Annelerin ve Bebeklerin Güvenilir Rehberi."}
                  </h1>
                </Grid>
                <Grid item style={{ width: !isMobile ? "10%" : null }}>
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      justifyContent: "center",
                      height: 85,
                    }}
                  >
                    <img
                      loading="lazy"
                      className="lazyload"
                      alt={
                        loc === "en"
                          ? "Week by Week Pregnancy Tracking"
                          : "Hafta Hafta Hamilelik Takibi"
                      }
                      data-src="/images/family.webp"
                      style={{
                        width: 70,
                        bottom: 0,
                        position: "absolute",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={isMobile ? 12 : null}
                  style={{ width: !isMobile ? "45%" : null }}
                >
                  <Grid
                    display="flex"
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid
                      item
                      xs={loc === "en" ? 4 : 3}
                      className="right-border"
                    >
                      <a
                        className="hide-link font-merriweather"
                        href={
                          loc === "en"
                            ? "/en/am-pregnant-test-do"
                            : "/hamile-miyim-test-yapmak"
                        }
                      >
                        <Grid
                          display="flex"
                          container
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid item xs={10}>
                            <h2
                              style={{
                                textAlign: "center",
                                color: "#ffffff",
                                fontSize: 16,
                              }}
                            >
                              {loc === "en"
                                ? "Am I Pregnant Test"
                                : "Hamile Miyim Testi"}
                            </h2>
                          </Grid>
                          <Grid item xs={2}>
                            <IconContext.Provider
                              value={{ color: "rgb(247 66 38)", size: "24px" }}
                            >
                              <MdKeyboardArrowRight />
                            </IconContext.Provider>
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                    <Grid item xs={4} className="right-border">
                      <a
                        className="hide-link font-merriweather"
                        href={
                          loc === "en"
                            ? "/en/how-many-months-am-pregnant"
                            : "/kac-aylik-hamileyim-hesaplama"
                        }
                      >
                        <Grid
                          display="flex"
                          container
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid item xs={10}>
                            <h2
                              style={{
                                textAlign: "center",
                                color: "#ffffff",
                                fontSize: 16,
                              }}
                            >
                              {loc === "en"
                                ? "How Many Months Am I Pregnant?"
                                : "Kaç Aylık Hamileyim?"}
                            </h2>
                          </Grid>
                          <Grid item xs={2}>
                            <IconContext.Provider
                              value={{ color: "rgb(247 66 38)", size: "24px" }}
                            >
                              <MdKeyboardArrowRight />
                            </IconContext.Provider>
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                    {loc !== "en" && (
                      <Grid item xs={4}>
                        <a
                          className="hide-link font-merriweather"
                          href="/kiz-erkek-bebek-isimleri"
                        >
                          <Grid
                            display="flex"
                            container
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Grid item xs={10}>
                              <h2
                                style={{
                                  textAlign: "center",
                                  color: "#ffffff",
                                  fontSize: 16,
                                }}
                              >
                                Kız Erkek Bebek İsimleri
                              </h2>
                            </Grid>
                            <Grid item xs={2}>
                              <IconContext.Provider
                                value={{
                                  color: "rgb(247 66 38)",
                                  size: "24px",
                                }}
                              >
                                <MdKeyboardArrowRight />
                              </IconContext.Provider>
                            </Grid>
                          </Grid>
                        </a>
                      </Grid>
                    )}
                    <Grid item xs={1}>
                      <a className="hide-link font-merriweather" href="/en">
                        <h2
                          style={{
                            textAlign: "center",
                            color: "#ffffff",
                            fontSize: 16,
                          }}
                        >
                          EN
                        </h2>
                      </a>
                      <a className="hide-link font-merriweather" href="/">
                        <h2
                          style={{
                            textAlign: "center",
                            color: "#ffffff",
                            fontSize: 16,
                          }}
                        >
                          TR
                        </h2>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}

      {isMobile && (
        <div
          style={{
            width: "100%",
            backgroundColor: "#442b48",
          }}
        >
          <Grid
            display="flex"
            container
            alignItems="center"
            justifyContent="center"
            style={{ padding: isMobile ? 10 : 0 }}
          >
            <Grid
              item
              xs={isMobile ? 12 : isNormalScreen ? 11 : isHighScreen ? 9 : 7}
            >
              <Grid
                display="flex"
                container
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={9}>
                  <h1
                    className="font-merriweather"
                    style={{ color: "#ffffff", fontSize: 16 }}
                  >
                    {loc === "en"
                      ? "Learn, grow together with Pregnancy and Pregnancy Tracker, Baby Development App."
                      : "Momercy: Annelerin ve Bebeklerin Güvenilir Rehberi."}
                  </h1>
                </Grid>
                <Grid item xs={3}>
                  <img
                    className="lazyload"
                    alt={
                      loc === "en"
                        ? "Week by Week Pregnancy Tracking"
                        : "Hafta Hafta Hamilelik Takibi"
                    }
                    data-src="/images/family.webp"
                    style={{ width: 90, height: 70, objectFit: "contain" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {isMobile && (
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 0,
            width: "100%",
            backgroundColor: "#ffffff",
          }}
        >
          <Grid
            display="flex"
            container
            alignItems="center"
            justifyContent="center"
            style={{ padding: isMobile ? 3 : 0 }}
          >
            <Grid
              item
              xs={isMobile ? 12 : isNormalScreen ? 11 : isHighScreen ? 9 : 7}
            >
              <Grid
                display="flex"
                container
                alignItems="center"
                justifyContent="center"
              >
                <Grid
                  item
                  xs={isMobile ? 12 : null}
                  style={{ width: !isMobile ? "45%" : null }}
                >
                  <Grid
                    display="flex"
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={3} className="right-border">
                      <a
                        className="hide-link font-merriweather"
                        href={
                          loc === "en"
                            ? "/en/am-pregnant-test-do"
                            : "/hamile-miyim-test-yapmak"
                        }
                      >
                        <Grid
                          display="flex"
                          container
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid item xs={10}>
                            <h2
                              style={{
                                textAlign: "center",
                                color: "#442b48",
                                fontSize: 16,
                              }}
                            >
                              {loc === "en"
                                ? "Online Pregnancy Test"
                                : "Hamile Miyim Testi"}
                            </h2>
                          </Grid>
                          <Grid item xs={2}>
                            <IconContext.Provider
                              value={{ color: "rgb(247 66 38)", size: "20px" }}
                            >
                              <MdKeyboardArrowRight />
                            </IconContext.Provider>
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                    <Grid item xs={4} className="right-border">
                      <a
                        className="hide-link font-merriweather"
                        href={
                          loc === "en"
                            ? "/en/how-many-months-am-pregnant"
                            : "/kac-aylik-hamileyim-hesaplama"
                        }
                      >
                        <Grid
                          display="flex"
                          container
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid item xs={10}>
                            <h2
                              style={{
                                textAlign: "center",
                                color: "#442b48",
                                fontSize: 16,
                              }}
                            >
                              {loc === "en"
                                ? "How Many Months Pregnant?"
                                : "Kaç Aylık Hamileyim?"}
                            </h2>
                          </Grid>
                          <Grid item xs={2}>
                            <IconContext.Provider
                              value={{ color: "rgb(247 66 38)", size: "20px" }}
                            >
                              <MdKeyboardArrowRight />
                            </IconContext.Provider>
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                    {loc !== "en" && (
                      <Grid item xs={4}>
                        <a
                          className="hide-link font-merriweather"
                          href="/kiz-erkek-bebek-isimleri"
                        >
                          <Grid
                            display="flex"
                            container
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Grid item xs={10}>
                              <h2
                                style={{
                                  textAlign: "center",
                                  color: "#442b48",
                                  fontSize: 16,
                                }}
                              >
                                Kız Erkek Bebek İsimleri
                              </h2>
                            </Grid>
                            <Grid item xs={2}>
                              <IconContext.Provider
                                value={{
                                  color: "rgb(247 66 38)",
                                  size: "20px",
                                }}
                              >
                                <MdKeyboardArrowRight />
                              </IconContext.Provider>
                            </Grid>
                          </Grid>
                        </a>
                      </Grid>
                    )}
                    {loc === "en" && (
                      <Grid item xs={4}>
                        <a
                          className="hide-link font-merriweather"
                          href="/en/articles/pregnancy-planning-ovulation/pregnancy-symptoms/MT-2-2"
                        >
                          <Grid
                            display="flex"
                            container
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Grid item xs={10}>
                              <h2
                                style={{
                                  textAlign: "center",
                                  color: "#442b48",
                                  fontSize: 16,
                                }}
                              >
                                Pregnancy Symptoms
                              </h2>
                            </Grid>
                            <Grid item xs={2}>
                              <IconContext.Provider
                                value={{
                                  color: "rgb(247 66 38)",
                                  size: "24px",
                                }}
                              >
                                <MdKeyboardArrowRight />
                              </IconContext.Provider>
                            </Grid>
                          </Grid>
                        </a>
                      </Grid>
                    )}
                    <Grid item xs={1}>
                      <a className="hide-link font-merriweather" href="/en">
                        <h2
                          style={{
                            textAlign: "center",
                            color: "#442b48",
                            fontSize: 16,
                          }}
                        >
                          EN
                        </h2>
                      </a>
                      <a className="hide-link font-merriweather" href="/">
                        <h2
                          style={{
                            textAlign: "center",
                            color: "#442b48",
                            fontSize: 16,
                          }}
                        >
                          TR
                        </h2>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}

      <Suspense fallback={renderLoader()}>
        <HomePageSlider
          isMobile={isMobile}
          isNormalScreen={isNormalScreen}
          isHighScreen={isHighScreen}
          loc={loc}
        />
      </Suspense>

      <div className="general-area" style={{ paddingTop: 0 }}>
        <Grid
          display="flex"
          container
          alignItems="center"
          justifyContent="center"
          style={{ padding: isMobile ? 5 : 0 }}
        >
          <Grid
            item
            xs={isMobile ? 12 : isNormalScreen ? 11 : isHighScreen ? 9 : 7}
            style={{ paddingBottom: 0 }}
          >
            <Grid container justifyContent="center">
              <Grid
                item
                xs={isMobile ? 12 : null}
                style={{ width: !isMobile ? "24%" : null }}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{ height: !isMobile ? 500 : null }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: 0,
                      backgroundColor: "#fff",
                      marginTop: 10,
                      marginRight: 5,
                      marginLeft: 2,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingRight: 10,
                    }}
                    className="shadow-area-now"
                  >
                    <a
                      className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                      href={
                        loc === "en"
                          ? "/en/articles/week-pregnant-tracking/nutrition/MT-1-5"
                          : "/anne-bebek-burc-uyumu-hesaplama"
                      }
                    >
                      <Grid
                        container
                        style={{ height: !isMobile ? 210 : null }}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          style={{ height: !isMobile ? 160 : null }}
                        >
                          <Grid item xs={7} style={{ paddingRight: 10 }}>
                            <h3 className="general-h3" style={{ fontSize: 16 }}>
                              {loc === "en" ? "PREGNANCY" : "HAMİLELİK"}
                            </h3>
                            <p
                              className="general-h2"
                              style={{
                                textAlign: "left",
                                fontSize: 20,
                                fontWeight: "900",
                              }}
                            >
                              {loc === "en"
                                ? "Tips from Breastfeeding Moms"
                                : "Anne Bebek Burç Uyumu Hesaplama"}
                            </p>
                          </Grid>
                          <Grid item xs={5}>
                            <LazyImage
                              head={
                                loc === "en"
                                  ? "Week by Week Pregnancy Tracking"
                                  : "Hafta Hafta Hamilelik Takibi"
                              }
                              image="https://amplify-amplify5fdda0668dad4-staging-120746-deployment.s3.eu-central-1.amazonaws.com/momercy/images/hafta_hafta_hamilelik_takibi.webp"
                              style={{
                                width: "100%",
                                objectFit: "contain",
                                height: 70,
                              }}
                              isMobile={isMobile}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          justifyContent="center"
                          style={{ padding: 0 }}
                        >
                          <p className="general-p2" style={{ padding: 0 }}>
                            {loc === "en"
                              ? "If your baby is called or crying, feed him."
                              : "Bebeğiniz ya da çocuğunuz ile burç uyumunuz nedir?"}
                          </p>
                        </Grid>
                      </Grid>
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: isMobile ? 10 : 10,
                      backgroundColor: "#fff",
                      marginTop: 10,
                      marginRight: 5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      marginLeft: 2,
                      paddingRight: 10,
                    }}
                    className="shadow-area-now"
                  >
                    <a
                      className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                      href={
                        loc === "en"
                          ? "/en/articles/birth-baby-development-tracking/new-born/MT-0-3"
                          : "/hamilelikte-1.trimester:-bilmeniz-gereken-her-sey"
                      }
                    >
                      <Grid
                        container
                        style={{ height: !isMobile ? 210 : null }}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          style={{ height: !isMobile ? 160 : null }}
                        >
                          <Grid item xs={7} style={{ paddingRight: 10 }}>
                            <h3 className="general-h3" style={{ fontSize: 16 }}>
                              {loc === "en" ? "BABY" : "BEBEK"}
                            </h3>
                            <p
                              className="general-h2"
                              style={{
                                textAlign: "left",
                                fontSize: 20,
                                fontWeight: "900",
                              }}
                            >
                              {loc === "en"
                                ? "Newborn baby bath"
                                : "Hamilelikte 1. Trimester: Bilmeniz Gerekenler"}
                            </p>
                          </Grid>
                          <Grid item xs={5}>
                            <LazyImage
                              head={
                                loc === "en"
                                  ? "Week by Week Baby Tracking"
                                  : "Hamilelikte 1. Trimester: Bilmeniz Gereken Her Şey"
                              }
                              image={
                                loc !== "en"
                                  ? "https://amplify-amplify5fdda0668dad4-staging-120746-deployment.s3.eu-central-1.amazonaws.com/momercy/images/21_50.webp"
                                  : "https://amplify-amplify5fdda0668dad4-staging-120746-deployment.s3.eu-central-1.amazonaws.com/momercy/images/hafta_hafta_bebek_gelisimi.webp"
                              }
                              style={{
                                width: "100%",
                                objectFit: "contain",
                                height: 70,
                              }}
                              isMobile={isMobile}
                            />
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                          <p className="general-p2">
                            {loc === "en"
                              ? "How do you wash your baby in the tub?"
                              : "Hamilelik süreci toplamda üç trimesterden oluşur."}
                          </p>
                        </Grid>
                      </Grid>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              {isMobile ? (
                <LazySection7 />
              ) : (
                <Grid
                  item
                  xs={isMobile ? 12 : null}
                  style={{ width: !isMobile ? "48%" : null }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: "100%" }}
                  >
                    <Grid
                      item
                      xs={12}
                      className="shadow-area-now"
                      style={{
                        backgroundColor: "#fff",
                        height: !isMobile ? 480 : null,
                        marginBottom: isMobile ? 10 : 30,
                        marginTop: 10,
                        marginRight: isMobile ? 5 : 20,
                        marginLeft: isMobile ? 5 : 20,
                      }}
                    >
                      <a
                        className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                        href={
                          loc === "en"
                            ? "/en/pregnancy-nutrition-health"
                            : "/hafta-hafta-bebek-gelisimi/16-hafta"
                        }
                      >
                        <LazyImage
                          head={
                            loc === "en"
                              ? "Nutrition During Pregnancy"
                              : "16. Hafta Bebek Gelişimi Tablosu Takibi"
                          }
                          image={
                            loc === "en"
                              ? "https://amplify-amplify5fdda0668dad4-staging-120746-deployment.s3.eu-central-1.amazonaws.com/momercy/images/hamilelikte_beslenme.webp"
                              : "https://amplify-amplify5fdda0668dad4-staging-120746-deployment.s3.eu-central-1.amazonaws.com/momercy/images/11_50.webp"
                          }
                          style={{
                            width: "100%",
                            height: "60%",
                            marginBottom: 20,
                          }}
                          isMobile={isMobile}
                        />
                        <h3 className="general-h3" style={{ fontSize: 16 }}>
                          {loc === "en"
                            ? "NUTRITION IN PREGNANCY"
                            : "HAMİLELİKTE SAĞLIK"}
                        </h3>
                        <p
                          className="general-h2"
                          style={{
                            textAlign: "left",
                            marginTop: 5,
                            marginBottom: 5,
                            fontSize: 20,
                            fontWeight: "900",
                          }}
                        >
                          {loc === "en"
                            ? "Nutrition and Health in Pregnancy Month by Month"
                            : "16. Hafta Bebek Gelişimi Tablosu Takibi"}
                        </p>
                        <p className="general-p2">
                          {loc === "en"
                            ? "It can be difficult to stick to the rules of weight gain during pregnancy."
                            : "Dört ayınız kutlu olsun! Bu, genellikle ebeveynlerin çoğunun bebeklerinin nasıl bu kadar hızlı büyüdüğüne şaşırdıkları dönemdir."}
                        </p>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {isMobile ? (
                <LazySection8 />
              ) : (
                <Grid
                  item
                  xs={isMobile ? 12 : null}
                  style={{ width: !isMobile ? "28%" : null }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: "100%" }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: isMobile ? 10 : 30,
                        backgroundColor: "#fff",
                        height: !isMobile ? 480 : null,
                        marginTop: 10,
                        marginRight:
                          isMobile || isNormalScreen || isHighScreen ? 5 : 5,
                        marginLeft:
                          isMobile || isNormalScreen || isHighScreen ? 5 : 5,
                        padding: 0,
                      }}
                      className="shadow-area-now"
                    >
                      <h3
                        className="general-p1"
                        style={{
                          fontSize: 24,
                          textAlign: "center",
                          color: "#ffffff",
                          backgroundColor: "#416165",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        {loc === "en" ? "Trends" : "Trendler"}
                      </h3>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          height: !isMobile ? 420 : null,
                          padding: 20,
                          paddingBottom: 10,
                          paddingTop: 0,
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ marginTop: 3, marginBottom: 3 }}
                        >
                          <a
                            className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                            href={
                              loc === "en"
                                ? "/en/articles/week-pregnant-tracking/baby/MT-5-4"
                                : "/hamilelikte-ilk-uc-ay-belirtiler"
                            }
                          >
                            <p
                              className="general-h2"
                              style={{
                                textAlign: "left",
                                fontSize: 20,
                                fontWeight: "900",
                              }}
                            >
                              {loc === "en"
                                ? "Week by Week Baby Movements"
                                : "Hamilelikte İlk Üç Ay"}
                            </p>
                            <p className="general-p2">
                              {loc === "en"
                                ? "Baby movements start from the 7th week."
                                : "Hamileliğin ilk üç ayı, birçok değişiklik ve heyecan verici gelişmelerin yaşandığı bir dönemdir."}
                            </p>
                          </a>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ marginTop: 3, marginBottom: 3 }}
                        >
                          <a
                            className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                            href={
                              loc === "en"
                                ? "/en/pregnancy-signs-symptoms/21"
                                : "/hamilelikte-16-hafta-bebegin-gelisimi"
                            }
                          >
                            <p
                              className="general-h2"
                              style={{
                                textAlign: "left",
                                fontSize: 20,
                                fontWeight: "900",
                              }}
                            >
                              {loc === "en"
                                ? "Change in Vaginal Discharge During Pregnancy"
                                : "Hamilelikte 16. Hafta: Bebeğinizin Gelişimi ve Annedeki Değişiklikler"}
                            </p>
                            <p className="general-p2">
                              {loc === "en"
                                ? "In this case, it's a good idea to call your healthcare provider."
                                : "Hamileliğinizin 16. haftasına hoş geldiniz! "}
                            </p>
                          </a>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ marginTop: 3, marginBottom: 3 }}
                        >
                          <a
                            className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                            href={
                              loc === "en"
                                ? "/en/articles/week-pregnant-tracking/health/MT-0-6"
                                : "/makaleler/hafta-hamile-gebelik-takibi/saglik/MT-0-59"
                            }
                          >
                            <p
                              className="general-h2"
                              style={{
                                textAlign: "left",
                                fontSize: 20,
                                fontWeight: "900",
                              }}
                            >
                              {loc === "en"
                                ? "When can I take a pregnancy test?"
                                : "Cinsiyet Tahmini İçin Tüm Yöntemler"}
                            </p>
                            <p className="general-p2">
                              {loc === "en"
                                ? "There are two types of pregnancy tests: urine test and blood test."
                                : "Geleneksel birçok yöntemle cinsiyet tahmini yapmak gerçekten oldukça eğlenceli olacak."}
                            </p>
                          </a>
                        </Grid>
                        {/*<Grid
                        item
                        xs={12}
                        style={{ marginTop: 3, marginBottom: 3 }}
                      >
                        <a
                          className="App-Main-Sub-Sub-Sub-Header App-Main-Outline"
                          href="/hamilelik-gebelik-belirtileri-semptomlar/46"
                        >
                          <p
                            className="general-h2"
                            style={{
                              textAlign: "left",
                              fontSize: 20,
                              fontWeight: "900",
                            }}
                          >
                            Hamilelikte Vajinal Kanama
                          </p>
                          <p className="general-p2">
                            Hamileyken adet döneminiz bitmiyor mu?
                          </p>
                        </a>
                          </Grid>*/}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      {isMobile ? (
        <LazySection6 />
      ) : (
        <Suspense fallback={renderLoader()}>
          <HomePagePopularTools
            isMobile={isMobile}
            isNormalScreen={isNormalScreen}
            isHighScreen={isHighScreen}
            expanded={expanded}
            loc={loc}
          />
        </Suspense>
      )}

      <div style={{ paddingTop: 20 }}>
        <LazySection5 />
      </div>

      <LazySection4 />

      <div style={{ paddingTop: isMobile ? 10 : 20 }}>
        <LazySection3 />
      </div>

      <div className="general-area">
        <LazySection />
      </div>

      <div className={`Page-Top-Area`} style={{ paddingTop: 20 }}>
        <Grid
          display="flex"
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={isMobile || isNormalScreen ? 11 : isHighScreen ? 9 : 7}
          >
            <h3
              className="general-h2"
              style={{ textAlign: "left", marginTop: 20 }}
            >
              {loc === "en"
                ? "Our Pregnancy and Baby Tracking Application"
                : "Hamilelik ve Bebek Takibi Uygulamamız"}
            </h3>
          </Grid>
          <Grid
            item
            xs={isMobile || isNormalScreen ? 11 : isHighScreen ? 9 : 7}
            style={{
              padding: isMobile ? 10 : 20,
              paddingTop: isMobile ? 20 : 40,
              paddingBottom: isMobile ? 20 : 40,
              marginTop: 10,
              borderRadius: 9,
              borderBottomLeftRadius: 0,
              backgroundColor: "#f3e8ee",
            }}
          >
            <Grid
              display="flex"
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={isMobile ? 12 : 4}>
                <h3
                  className="general-h33"
                  style={{ fontSize: 22, fontWeight: "900", marginBottom: 10 }}
                >
                  {loc === "en"
                    ? "Complete the pregnancy process, baby development and care."
                    : "Hamilelik sürecini, bebek gelişimini ve bakımını eksiksiz uygulayın."}
                </h3>

                <Grid
                  container
                  style={{
                    marginTop: isMobile ? 10 : 30,
                    marginBottom: isMobile ? 10 : 0,
                  }}
                >
                  <Grid item xs={isMobile ? 6 : 5} style={{ height: 80 }}>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.annebebek.annemindunyasi&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                      onClick={() =>
                        openInNewTab(
                          "https://play.google.com/store/apps/details?id=com.annebebek.annemindunyasi&hl"
                        )
                      }
                    >
                      <img
                        style={{
                          width: isMobile ? "90%" : "100%",
                          height: isMobile ? 67 : 64,
                        }}
                        alt={
                          loc === "en"
                            ? "Pregnancy Tracking App"
                            : "Hamilelik Takibi Uygulama"
                        }
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      />
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 6 : 5}
                    style={{ marginTop: 10, height: isMobile ? 55 : 45 }}
                  >
                    <a
                      href={
                        "https://apps.apple.com/" +
                        (loc === "en" ? "en" : "tr") +
                        "/app/annemin-d%C3%BCnyas%C4%B1/id1447682983"
                      }
                      target="_blank"
                      rel="noreferrer"
                      onClick={() =>
                        openInNewTab(
                          "https://apps.apple.com/" +
                            (loc === "en" ? "en" : "tr") +
                            "/app/annemin-d%C3%BCnyas%C4%B1/id1447682983"
                        )
                      }
                    >
                      <img
                        loading="lazy"
                        className="lazyload"
                        style={{ width: isMobile ? 140 : "80%" }}
                        alt="Bebek Takibi Uygulama"
                        data-src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png"
                      />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={isMobile ? 12 : 8}>
                <Grid
                  display="flex"
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid
                    item
                    xs={isMobile ? 6 : 3}
                    style={{ height: 220, padding: 10 }}
                  >
                    <img
                      loading="lazy"
                      className="lazyload"
                      alt="Hamilelik Takibi ve Bebek Gelişimi Makaleler"
                      data-src="/images/hamilelik-makaleleri.png"
                      style={{ width: 120, objectFit: "contain", height: 100 }}
                    />
                    <p className="general-p2" style={{ marginTop: 10 }}>
                      {loc === "en"
                        ? "Thousands of original articles written by experts"
                        : "Uzmanlar tarafından yazılan binlerce orjinal makale"}
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 6 : 3}
                    style={{ height: 220, padding: 10 }}
                  >
                    <img
                      loading="lazy"
                      className="lazyload"
                      alt="Hamilelik ve Gebelik Takip Araçları"
                      data-src="/images/hamilelik_takibi_gebelik.png"
                      style={{ width: 120, objectFit: "contain", height: 100 }}
                    />
                    <p className="general-p2" style={{ marginTop: 10 }}>
                      {loc === "en"
                        ? "Dozens of tracking tools needed during pregnancy"
                        : "Hamilelikte ihtiyaç duyulan onlarca takip aracı"}
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 6 : 3}
                    style={{ height: 220, padding: 10 }}
                  >
                    <img
                      loading="lazy"
                      className="lazyload"
                      alt="Anne Bebek Takip Araçları"
                      data-src="/images/bebek_gelisimi_takibi.webp"
                      style={{ width: 120, objectFit: "contain", height: 100 }}
                    />
                    <p className="general-p2" style={{ marginTop: 10 }}>
                      {loc === "en"
                        ? "Dozens of tracking tools needed in baby development"
                        : "Bebek gelişiminde ihtiyaç duyulan onlarca takip aracı"}
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 6 : 3}
                    style={{ height: 220, padding: 10 }}
                  >
                    <img
                      loading="lazy"
                      alt="Hamilelik Soru Cevap Forum"
                      className="lazyload"
                      data-src="/images/anne_soru_cevap.webp"
                      style={{ width: 120, objectFit: "contain", height: 100 }}
                    />
                    <p className="general-p2" style={{ marginTop: 10 }}>
                      {loc === "en"
                        ? "Online Q&A with thousands of people on pregnancy and motherhood"
                        : "Hamilelik ve annelik üzerine binlerce kişi ile online soru cevap"}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <LazySection2 />
    </div>
  );
}

export default HomePage;
