import React, { useState } from "react";
import "../Header.css";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useLocation } from "react-router-dom";

function PublicHeader(props) {
  const { isMobile } = props;
  const location = useLocation();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const loc = location?.pathname?.split("/")[1];
  const closeMobileMenu = () => setClick(false);
  return (
    <div className="top-bar">
      <div className="public-header">
        <Grid
          style={{ paddingLeft: 20, paddingRight: 20 }}
          display="flex"
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={isMobile ? 1 : false}>
            <div className="mobile-menu" onClick={handleClick}>
              {click ? (
                <span className="close-btn">
                  <img
                    src="/images/cancel.png"
                    alt="Hamilelik Takibi ve Bebek Gelişimi Download"
                    width={25}
                    height={25}
                  />
                </span>
              ) : (
                <span className="close-btn">
                  <img
                    src="/images/menu.png"
                    alt="Hamilelik Takibi ve Bebek Gelişimi Download"
                    width={25}
                    height={25}
                  />
                </span>
              )}
            </div>
          </Grid>
          <Grid item xs={isMobile ? 10 : 12}>
            <Grid
              display="flex"
              container
              alignItems="center"
              justifyContent="center"
            >
              <Link to={loc === "en" ? "/en" : "/"} className="head_logo_link">
                <img
                  data-src="https://amplify-amplify5fdda0668dad4-staging-120746-deployment.s3.eu-central-1.amazonaws.com/momercy/images/logo+for+web.png"
                  alt={
                    loc === "en"
                      ? "Pregnancy Pregnancy Mom Baby App - Momercy"
                      : "Hamilelik Gebelik Anne Bebek Uygulaması - Momercy"
                  }
                  className="head_logo lazyload"
                />
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={isMobile ? 1 : false}></Grid>
        </Grid>
      </div>
      <div className={click ? "headerMob" : "header"}>
        <div className="logo-nav">
          <ul className={click ? "nav-options active" : "nav-options"}>
            <li className="option" onClick={closeMobileMenu}>
              <Link
                className="option option-link font-merriweather"
                to={loc === "en" ? "/en/about-us" : "/hakkimizda"}
              >
                {loc === "en" ? "About Us" : "Momercy Hakkında"}
              </Link>
            </li>
            <li className="option" onClick={closeMobileMenu}>
              <Link
                className="option option-link font-merriweather"
                to={
                  loc === "en"
                    ? "/en/pregnancy-planning-ovulation"
                    : "/hamilelik-planlama-ovulasyon"
                }
              >
                {loc === "en" ? "Pregnancy Planning" : "Hamilelik Planlama"}
              </Link>
            </li>
            <li className="option" onClick={closeMobileMenu}>
              <Link
                className="option option-link font-merriweather"
                to={
                  loc === "en"
                    ? "/en/week-pregnant-tracking"
                    : "/hafta-hamile-gebelik-takibi"
                }
              >
                {loc === "en" ? "Pregnancy" : "Hamilelik Süreci"}
              </Link>
            </li>
            <li className="option" onClick={closeMobileMenu}>
              <Link
                className="option option-link font-merriweather"
                to={
                  loc === "en"
                    ? "/en/birth-baby-development-tracking"
                    : "/dogum-bebek-gelisimi-takibi"
                }
              >
                {loc === "en" ? "Baby" : "Bebek Bakımı"}
              </Link>
            </li>
            {isMobile && (
              <li className="option" onClick={closeMobileMenu}>
                <Link
                  className="option option-link font-merriweather"
                  to={
                    loc === "en"
                      ? "/en/mother-child-development-follow-up"
                      : "/anne-cocuk-gelisimi-takibi"
                  }
                >
                  {loc === "en" ? "Kid" : "Çocuk"}
                </Link>
              </li>
            )}
            {/*<li className="option" onClick={closeMobileMenu}>
              <Link
                className="option option-link font-merriweather"
                to={
                  loc === "en"
                    ? "/en/mother-child-development-follow-up"
                    : "/anne-cocuk-gelisimi-takibi"
                }
              >
                {loc === "en" ? "Kid" : "Çocuk"}
              </Link>
              </li>*/}
            <li className="option" onClick={closeMobileMenu}>
              <Link
                className="option option-link font-merriweather"
                to={
                  loc === "en"
                    ? "/en/mom-pregnant-chat-community-question-answer"
                    : "/anne-hamile-sohbet-topluluk-soru-cevap"
                }
              >
                {loc === "en" ? "Community" : "Forum"}
              </Link>
            </li>
            <li className="option" onClick={closeMobileMenu}>
              <Link
                className="option option-link font-merriweather"
                to={
                  loc === "en"
                    ? "/en/mother-child-baby-product-recommendations"
                    : "/anne-cocuk-bebek-urun-onerileri"
                }
              >
                {loc === "en" ? "Product Advices" : "Ürün Önerileri"}
              </Link>
            </li>
            <li className="option" onClick={closeMobileMenu}>
              <Link
                className="option option-link font-merriweather"
                to={
                  loc === "en"
                    ? "/en/pregnancy-and-baby-tools"
                    : "/hamilelik-ve-bebek-araclari"
                }
              >
                {loc === "en" ? "Tools" : "Hesaplama Araçları"}
              </Link>
            </li>
            {/*<li className="option" onClick={closeMobileMenu}>
                            <Link className="option option-link" to="/anne-cocuk-bebek-ayricalikli-konular">Ayrıcalıklı Konular</Link>
                        </li>*/}
            {/*
                        <li className="option mobile-option" onClick={closeMobileMenu}>
                            <a href="#">SIGN-IN</a>
                        </li>
                        <li className=" option mobile-option" onClick={closeMobileMenu}>
                        <a href="" className="sign-up">
                            SIGN-UP
                        </a>
                        </li>
                        */}
          </ul>
        </div>
        {/*
                <ul className="signin-up">
                    <li className="sign-in" onClick={closeMobileMenu}>
                        <a href="#">SIGN-IN</a>
                    </li>
                    <li onClick={closeMobileMenu}>
                        <a href="" className="signup-btn">
                        SIGN-UP
                        </a>
                    </li>
                </ul>
                */}
      </div>
    </div>
  );
}

export default PublicHeader;
